import { Stack } from "@mui/joy";
import { memo } from "react";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import ControlledInput from "../ControlledInput/ControlledInput";

interface S3BucketFormControlsProps {
  type: ExternalConnectionType;
}

const S3BucketFormControls: React.FC<S3BucketFormControlsProps> = memo(
  ({ type }) => {
    return (
      <Stack>
        <ExternalConnectionConfigurationLabel />
        <ControlledInput
          label="Bucket name"
          name="s3.sourceConfig.bucket"
          placeholder={`e.g. "my-bucket-name"`}
        />
        <ControlledInput
          label="Region code"
          name="s3.sourceConfig.region"
          placeholder={`e.g. "eu-central-2"`}
        />
        <ControlledInput
          label="Object name (Key)"
          name="s3.sourceConfig.objectKey"
          placeholder={`e.g. "my-file.csv"`}
        />
        {type === ExternalConnectionType.IMPORT ? (
          <ControlledInput
            label="Stored dataset name"
            name="datasetName"
            placeholder={`e.g. "dataset-name"`}
            required={false}
          />
        ) : null}
        <ExternalConnectionCredentialsLabel />
        <ControlledInput
          label="Access key"
          name="s3.credentials.accessKey"
          placeholder={`e.g. "5JG8C6HNDPQF7W2X3Y1Z"`}
        />
        <ControlledInput
          label="Secret key"
          name="s3.credentials.secretKey"
          placeholder={`e.g. "F57yT3QaX2nD8jHr4KlP9cVs1W6zU0E2sY8hN4bM"`}
        />
      </Stack>
    );
  }
);
S3BucketFormControls.displayName = "S3BucketFormControls";

export default S3BucketFormControls;
