import { DataSourceType } from "@decentriq/graphql/dist/types";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemDecorator,
  Radio,
  RadioGroup,
  styled,
} from "@mui/joy";
import { Fragment, memo } from "react";
import {
  dataSourceTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { useDocsLink } from "hooks";

const DocsLink = styled("a")({
  alignItems: "baseline",
  color: "inherit",
});

type ExternalDataSourceSelectorProps = {
  onTypeChange: (type: DataSourceType) => void;
  selectedType: DataSourceType | null;
};

const ExternalDataSourceSelector: React.FC<ExternalDataSourceSelectorProps> = ({
  onTypeChange: setSelectedDataSource,
  selectedType: selectedDataSource,
}) => {
  const docsBaseLink = useDocsLink();
  const dataSourceOptions: DataSourceType[] = [
    DataSourceType.S3,
    DataSourceType.Azure,
    DataSourceType.GoogleCloudStorage,
    DataSourceType.Snowflake,
    DataSourceType.Salesforce,
    DataSourceType.Permutive,
  ];
  return (
    <Fragment>
      <RadioGroup
        onChange={(event) =>
          setSelectedDataSource(event.target.value as DataSourceType)
        }
        value={selectedDataSource}
      >
        <List
          sx={{
            "--List-gap": "0.5rem",
            "--List-padding": "1rem",
            "--ListDivider-gap": "1rem",
            "--ListItem-paddingY": "1rem",
            "--ListItem-radius": "8px",
            "--ListItemDecorator-size": "32px",
          }}
        >
          {dataSourceOptions.map((dataSource) => (
            <ListItem
              key={dataSource}
              sx={{ boxShadow: "sm" }}
              variant="outlined"
            >
              <ListItemDecorator>
                <ExternalConnectionsIcon
                  connectionType={dataSource}
                  size={ExternalConnectionsIconSize.sm} // NOTE: Redundant, `--ListItemDecorator-size` can handle this
                />
              </ListItemDecorator>
              <Radio
                color="primary"
                label={dataSourceTypePresentation.get(dataSource)}
                overlay={true}
                slotProps={{
                  action: ({ checked }) => ({
                    sx: (theme) => ({
                      ...(checked && {
                        border: "2px solid",
                        borderColor: theme.vars.palette.primary[500],
                        inset: -1,
                      }),
                    }),
                  }),
                }}
                sx={{ flexDirection: "row-reverse", flexGrow: 1 }}
                value={dataSource}
              />
            </ListItem>
          ))}
        </List>
      </RadioGroup>
      <Divider />
      <DialogContent sx={{ flex: "0 0 auto" }}>
        {selectedDataSource === DataSourceType.S3 && (
          <Alert
            slotProps={{
              root: { sx: { alignItems: "flex-start" } },
              startDecorator: { sx: { display: "block" } },
            }}
            startDecorator={<FontAwesomeIcon icon={faCircleInfo} />}
            variant="soft"
          >
            <Box>
              <Box>
                This will import any object from an Amazon S3 Bucket and store
                it as a dataset in the Decentriq platform.
              </Box>
              <Box>
                Note: The imported file/dataset will be stored as is, without
                any data manipulation.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataSource === DataSourceType.Azure && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will import any object from an Azure Blob Storage Container
                and store it as a dataset in the Decentriq platform.
              </Box>
              <Box>
                Note: The imported file/dataset will be stored as is, without
                any data manipulation.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataSource === DataSourceType.GoogleCloudStorage && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will import any object from a Google Cloud Storage Bucket
                and store it as a dataset in the Decentriq platform.
              </Box>
              <Box>
                Note: The imported file/dataset will be stored as is, without
                any data manipulation.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataSource === DataSourceType.Snowflake && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will import the contents of any table or query data
                unloaded to a Snowflake stage, and store it as a dataset in the
                Decentriq platform.
              </Box>
              <Box>
                Note: please{" "}
                <DocsLink
                  href={`${docsBaseLink}/data-management/import/from-external-source/supported-sources#snowflake`}
                  rel="noreferrer"
                  target="_blank"
                >
                  follow the instructions
                </DocsLink>{" "}
                to prepare your data for import.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataSource === DataSourceType.Salesforce && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will import any object from Salesforce and store it as a
                dataset in the Decentriq platform.
              </Box>
              <Box>
                Note: The imported file/dataset will be stored as is, without
                any data manipulation.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataSource === DataSourceType.Permutive && (
          <Alert variant="plain">
            <Box>
              This will import Publisher datasets from Permutive via a Cloud
              Storage service and store them as datasets in the Decentriq
              platform. These datasets can then be provisioned directly to
              Decentriq datalabs for Publishers.
            </Box>
          </Alert>
        )}
      </DialogContent>
    </Fragment>
  );
};

ExternalDataSourceSelector.displayName = "ExternalDataSourceSelector";

export default memo(ExternalDataSourceSelector);
