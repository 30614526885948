import { faWrench } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/joy";
import { memo } from "react";

const ExternalConnectionConfigurationLabel: React.FC = () => {
  return (
    <Box>
      <Typography
        alignItems="center"
        display="flex"
        gap={0.75}
        level="title-md"
      >
        <FontAwesomeIcon icon={faWrench} />
        <span>Configuration</span>
      </Typography>
    </Box>
  );
};

ExternalConnectionConfigurationLabel.displayName =
  "ExternalConnectionConfigurationLabel";

export default memo(ExternalConnectionConfigurationLabel);
