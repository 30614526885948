import { zodResolver } from "@hookform/resolvers/zod";
import { Button, DialogActions, DialogContent, Divider } from "@mui/joy";
import merge from "lodash/merge";
import { memo, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import { ExternalConnectionType } from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "../../../../types";
import AzureFormControls from "./AzureFormControls";
import { defaultValues, schema, type SchemaType } from "./model";

const FORM_ID = "form";

type AzureFormProps = (ImportExternalDataFormProps | ExportDatasetFormProps) & {
  type: ExternalConnectionType;
};

const AzureForm: React.FC<AzureFormProps> = memo(
  ({
    defaultValues: defaultValuesProp = defaultValues,
    onCancel,
    onSubmit,
    type = ExternalConnectionType.IMPORT,
  }) => {
    const [disabled, setDisabled] = useState(false);
    const form = useForm({
      defaultValues: merge(defaultValues, defaultValuesProp),
      disabled,
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(schema),
    });
    const { handleSubmit, reset, formState } = form;
    const { isSubmitting } = formState; // TODO: Manage Escape with `isDirty`
    const onBackClick = useCallback(() => {
      reset();
      onCancel();
    }, [reset, onCancel]);
    const handleFormSubmit = useCallback(
      async (formValues: SchemaType) => {
        const {
          configuration: {
            blobName = "",
            storageAccount = "",
            storageContainer = "",
          } = {},
          credentials: { sasToken = "" } = {},
          datasetName = "",
        } = formValues;
        return await onSubmit({
          // TODO: Cast as either of two handlers
          input: {
            azure: {
              credentials: {
                blobName: blobName.trim(),
                sasToken: sasToken.trim(),
                storageAccount: storageAccount.trim(),
                storageContainer: storageContainer.trim(),
              },
            },
            ...(type === ExternalConnectionType.IMPORT
              ? { datasetName: datasetName?.trim() || blobName?.trim() }
              : {}),
          },
        });
      },
      [onSubmit, type]
    );
    useEffect(() => {
      setDisabled(isSubmitting);
    }, [isSubmitting]);
    // TODO: Reset form
    return (
      <FormProvider {...form}>
        <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)}>
          <DialogContent>
            <AzureFormControls type={type} />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button
              disabled={disabled}
              form={FORM_ID}
              onClick={onBackClick}
              type="reset"
            >
              Back
            </Button>
            <Button
              color="primary"
              disabled={disabled}
              form={FORM_ID}
              loading={isSubmitting}
              loadingPosition="start"
              type="submit"
              variant="solid"
            >
              {type === ExternalConnectionType.IMPORT ? "Import" : "Export"}
            </Button>
          </DialogActions>
        </form>
      </FormProvider>
    );
  }
);
AzureForm.displayName = "AzureForm";

export default AzureForm;
