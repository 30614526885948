import { SalesforceProductType } from "@decentriq/graphql/dist/types";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { SalesforceProductTypeMap } from "features/datasets/components/ExternalConnectionConfiguration/components";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import ControlledInput from "../ControlledInput/ControlledInput";

interface SalesforceFormControlsProps {
  type: ExternalConnectionType;
}

const SalesforceFormControls: React.FC<SalesforceFormControlsProps> = memo(
  ({ type }) => {
    const { control } = useFormContext();
    return (
      <Stack>
        <ExternalConnectionConfigurationLabel />
        <Controller
          control={control}
          name="salesforce.productType"
          render={({ field, fieldState }) => {
            const { error } = fieldState;
            return (
              <FormControl
                disabled={field.disabled}
                error={Boolean(error)}
                required={true}
              >
                <FormLabel>Product type</FormLabel>
                <Select
                  {...field}
                  onChange={(event, value) => field.onChange(value)}
                >
                  {Object.values(SalesforceProductType).map((value) => (
                    <Option
                      disabled={value === SalesforceProductType.MarketingCloud}
                      key={value}
                      value={value}
                    >
                      <Box>
                        <Box>
                          {SalesforceProductTypeMap.get(
                            value as SalesforceProductType
                          )}
                        </Box>
                        {value === SalesforceProductType.MarketingCloud ? (
                          <Typography level="body-sm" sx={{ color: "inherit" }}>
                            Please contact support
                          </Typography>
                        ) : null}
                      </Box>
                    </Option>
                  ))}
                </Select>
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <ControlledInput
          label="My domain"
          name="salesforce.domainUrl"
          placeholder={`e.g. "owndomain.my.salesforce.com"`}
        />
        <ControlledInput
          label="Salesforce Object"
          name="salesforce.apiName"
          placeholder={`e.g. "CustomerList__c"`}
        />
        {type === ExternalConnectionType.IMPORT ? (
          <ControlledInput
            label="Stored dataset name"
            name="datasetName"
            placeholder={`e.g. "DQ_dataset_name"`}
            required={false}
          />
        ) : null}
        <ExternalConnectionCredentialsLabel />
        <ControlledInput
          label="Client ID"
          name="salesforce.credentials.clientId"
          placeholder={`e.g. "4NBTEYKgiArB1Wngttv8hHxLezlGuPVYihk…"`}
        />
        <ControlledInput
          label="Client secret"
          name="salesforce.credentials.clientSecret"
          placeholder={`e.g. "EEA998BF910D4332392091999…"`}
        />
      </Stack>
    );
  }
);
SalesforceFormControls.displayName = "SalesforceFormControls";

export default SalesforceFormControls;
