// TODO: Fix joy migration
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "../../../../types";
import S3BucketFormConfigurationFields from "./components/S3BucketFormConfigurationFields";
import S3BucketFormCredentials from "./components/S3BucketFormCredentials";

type S3BucketFormProps = (
  | ImportExternalDataFormProps
  | ExportDatasetFormProps
) & {
  type: ExternalConnectionType;
  ActionsWrapper?: React.ComponentType;
  FormWrapper?: React.ComponentType;
};

const s3BucketFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    filePath: yup.string().trim().required("File path is required"),
    region: yup.string().trim().required("Region is required"),
    url: yup.string().trim().required("S3 Bucket URL is required"),
  }),
  credentials: yup.object({
    accessKey: yup.string().trim().required("Access Key is required"),
    secretKey: yup.string().trim().required("Secret Key is required"),
  }),
  datasetName: yup.string(),
});

// TODO: Consider creating separate forms for import and export
const S3BucketForm: React.FC<S3BucketFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText = "Import",
  defaultValues,
  type = ExternalConnectionType.IMPORT,
  ActionsWrapper = ExternalConnectionActionsWrapper,
  FormWrapper = Box,
}) => {
  const form = useForm({
    defaultValues: {
      configuration: {
        filePath:
          type === ExternalConnectionType.IMPORT
            ? ""
            : defaultValues?.datasetName,
        region: "",
        url: "",
      },
      credentials: {
        accessKey: "",
        secretKey: "",
      },
      ...(type === ExternalConnectionType.IMPORT
        ? { datasetName: defaultValues?.datasetName || "" }
        : {}),
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(s3BucketFormValidationSchema),
  });
  const { control, handleSubmit, reset } = form;

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: any = {}) => {
      const {
        configuration: { url = "", region = "", filePath = "" } = {},
        credentials: { accessKey = "", secretKey = "" } = {},
        datasetName,
      } = formValues;

      const config = {
        bucket: url.trim(),
        objectKey: filePath.trim(),
        region: region.trim(),
      };

      const s3BucketConfigVariables =
        type === ExternalConnectionType.IMPORT
          ? { sourceConfig: config }
          : { targetConfig: config };

      onSubmit({
        input: {
          ...(type === ExternalConnectionType.IMPORT
            ? { datasetName: datasetName?.trim() || filePath?.trim() }
            : {}),
          s3: {
            credentials: {
              accessKey: accessKey.trim(),
              secretKey: secretKey.trim(),
            },
            ...s3BucketConfigVariables,
          },
        },
      });
      reset();
    },
    [reset, onSubmit, type]
  );

  return (
    <>
      <FormWrapper>
        <Box sx={{ p: 2 }}>
          <FormProvider {...form}>
            <Stack>
              <Stack>
                <ExternalConnectionConfigurationLabel />
                <S3BucketFormConfigurationFields />
                <Controller
                  control={control}
                  name="configuration.filePath"
                  render={({ field, formState }) => {
                    const { errors } = formState;
                    const fieldError = errors?.configuration?.filePath;
                    return (
                      <FormControl error={!isEmpty(fieldError)} required={true}>
                        <FormLabel>Object name (Key)</FormLabel>
                        <Input placeholder={`e.g. "my-file.csv"`} {...field} />
                        <FormHelperText>{fieldError?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
                {type === ExternalConnectionType.IMPORT && (
                  <Controller
                    control={control}
                    name="datasetName"
                    render={({ field, formState }) => {
                      const { errors } = formState;
                      const fieldError = errors?.datasetName;
                      return (
                        <FormControl error={!isEmpty(fieldError)}>
                          <FormLabel>Stored dataset name</FormLabel>
                          <Input
                            placeholder={`e.g. "DQ_dataset_name"`}
                            {...field}
                          />
                          <FormHelperText>{fieldError?.message}</FormHelperText>
                        </FormControl>
                      );
                    }}
                  />
                )}
              </Stack>
              <S3BucketFormCredentials />
            </Stack>
          </FormProvider>
        </Box>
      </FormWrapper>
      <Divider />
      <ActionsWrapper>
        <Button onClick={handlePreviousStepClick}>Back</Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="solid"
        >
          {submitButtonText}
        </Button>
      </ActionsWrapper>
    </>
  );
};

S3BucketForm.displayName = "S3BucketForm";

export default memo(S3BucketForm);
