// TODO: Fix joy migration
import {
  useOrganizationLicenseQuery,
  useUpdateOrganizationLicenseMutation,
} from "@decentriq/graphql/dist/hooks";
import { OrganizationState } from "@decentriq/graphql/dist/types";
import { Box, Chip, FormControl, FormLabel, Option, Select } from "@mui/joy";
import { memo, useCallback } from "react";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { getEffectiveErrorMessage } from "utils";
import OrganizationLicenseTypesTooltip from "./OrganizationLicenseTypesTooltip";

interface OrganizationLicenseEditorProps {
  organizationId: string;
}

const OrganizationLicenseEditor: React.FC<OrganizationLicenseEditorProps> = ({
  organizationId,
}) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const { state } = organizationLicenseData?.organization || {};

  const [updateOrganizationLicenseMutation] =
    useUpdateOrganizationLicenseMutation({
      onCompleted: () => {
        enqueueSnackbar("Organization licensing has been updated");
      },
    });

  const updateOrganizationLicense = useCallback(
    async ({ target: { value } }) => {
      try {
        await updateOrganizationLicenseMutation({
          update: (cache) => {
            cache.modify({
              fields: {
                state: () => {
                  return value;
                },
              },
              id: cache.identify({
                __typename: "Organization",
                id: organizationId,
              }),
            });
          },
          variables: {
            organizationId,
            state: value as OrganizationState,
          },
        });
      } catch (error) {
        enqueueSnackbar("Organization license status could not be updated.", {
          context: getEffectiveErrorMessage(error),
          persist: true,
          variant: "error",
        });
      }
    },
    [organizationId, enqueueSnackbar, updateOrganizationLicenseMutation]
  );

  const ORGANIZATION_STATE_MENU_ITEMS = [
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Subscribed,
      title: OrganizationState.Subscribed,
      value: OrganizationState.Subscribed,
    },
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Suspended ||
        state === OrganizationState.Archived,
      title: OrganizationState.Suspended,
      value: OrganizationState.Suspended,
    },
    {
      disabled:
        state === OrganizationState.Passive ||
        state === OrganizationState.Archived,
      title: OrganizationState.Trial,
      value: OrganizationState.Trial,
    },
    {
      disabled: true,
      title: OrganizationState.Passive,
      value: OrganizationState.Passive,
    },
  ];

  return (
    <FormControl>
      <FormLabel>
        License status
        {isSuperAdmin && <OrganizationLicenseTypesTooltip />}
      </FormLabel>
      {isSuperAdmin ? (
        <Select<OrganizationState>
          defaultValue={state}
          onChange={updateOrganizationLicense}
          renderValue={({ value }) => {
            if (state === OrganizationState.Archived) {
              return OrganizationState.Archived;
            }
            return value || state;
          }}
          value={state}
        >
          {ORGANIZATION_STATE_MENU_ITEMS.map(({ disabled, title, value }) => (
            <Option disabled={disabled} key={value} value={value}>
              {title}
            </Option>
          ))}
        </Select>
      ) : (
        <Box sx={{ mt: 0.5 }}>
          <Chip>{state?.toUpperCase()}</Chip>
        </Box>
      )}
    </FormControl>
  );
};

OrganizationLicenseEditor.displayName = "OrganizationLicenseEditor";

export default memo(OrganizationLicenseEditor);
