// TODO: Fix joy migration
import { InfoTooltip } from "@decentriq/components";
import {
  type DataTargetType,
  PermutiveServiceProvider,
} from "@decentriq/graphql/dist/types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ListItemDecorator,
  Option,
  Select,
  Stack,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { Fragment, memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { ExternalConnectionsIcon } from "features/datasets";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import { ExternalConnectionConfigurationLabel } from "features/datasets/components/ExternalConnections";
import {
  GoogleCloudStorageFormBucketField,
  GoogleCloudStorageFormCredentials,
  S3BucketFormConfigurationFields,
  S3BucketFormCredentials,
} from "features/datasets/components/ImportData/ImportExternalDataDialog/components";
import { dataTargetTypePresentation } from "features/datasets/models";

type PermutiveFormProps = ExportDatasetFormProps;

const PermutiveFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    apiKey: yup.string().trim().required("Permutive API Key is required"),
    bucketName: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.GoogleCloudStorage,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Bucket name is required"),
      }),
    importId: yup.string().trim().required("Import ID is required"),
    objectName: yup.string().trim().required("Object name is required"),
    region: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.S3,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Region is required"),
      }),
    segmentCode: yup.string().trim().required("Segment code is required"),
    segmentName: yup.string().trim().required("Segment name is required"),
    serviceProvider: yup
      .mixed()
      .oneOf(Object.values(PermutiveServiceProvider))
      .required("Service provider is required"),
    url: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.S3,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Bucket URL is required"),
      }),
  }),
  credentials: yup.object().when("configuration.serviceProvider", {
    is: (serviceProvider: PermutiveServiceProvider) =>
      serviceProvider === PermutiveServiceProvider.S3,
    otherwise: () =>
      yup.object().shape({
        accessKey: yup.string(),
        credentialsFile: yup.mixed().required("Credentials file is required"),
        secretKey: yup.string(),
      }),
    then: () =>
      yup.object().shape({
        accessKey: yup.string().trim().required("Access key is required"),
        credentialsFile: yup.mixed(),
        secretKey: yup.string().trim().required("Secret Key is required"),
      }),
  }),
});

// TODO: figure out typings here
type PermutiveFormValues = yup.Asserts<typeof PermutiveFormValidationSchema>;

const PermutiveForm: React.FC<PermutiveFormProps> = ({
  onSubmit: submitFormHandler,
  onCancel: cancelFormHandler,
  defaultValues,
}) => {
  const form = useForm({
    defaultValues: {
      configuration: {
        apiKey: "",
        bucketName: "", // Cloud Storage configuration
        importId: "",
        objectName: defaultValues?.datasetName || "",
        region: "", // S3 bucket configuration
        segmentCode: "",
        segmentName: "",
        serviceProvider: PermutiveServiceProvider.GoogleCloudStorage,
        url: "", // S3 bucket configuration
      },
      credentials: {
        accessKey: "", // S3 bucket credentials
        credentialsFile: undefined, // Cloud Storage Credentials
        secretKey: "", // S3 bucket credentials
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(PermutiveFormValidationSchema),
  });
  const { control, handleSubmit, reset, watch } = form;

  const cloudProviderValue = watch("configuration.serviceProvider");

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: PermutiveFormValues) => {
      const {
        configuration: {
          serviceProvider,
          importId = "",
          segmentCode = "",
          segmentName = "",
          apiKey = "",
          bucketName = "",
          objectName = "",
          url = "",
          region = "",
        } = {},
        credentials: { accessKey = "", secretKey = "", credentialsFile } = {},
      } = formValues;

      const permutiveCommonFields = {
        credentials: {
          apiKey: apiKey.trim(),
        },
        importId: importId.trim(),
        inputHasHeader: false,
        segmentCode: segmentCode.trim(),
        segmentName: segmentName.trim(),
      };

      if (serviceProvider === PermutiveServiceProvider.S3) {
        submitFormHandler({
          input: {
            permutive: {
              aws: {
                credentials: {
                  accessKey: accessKey.trim(),
                  secretKey: secretKey.trim(),
                },
                targetConfig: {
                  bucket: url.trim(),
                  objectKey: objectName.trim(),
                  region: region.trim(),
                },
              },
              ...permutiveCommonFields,
            },
          },
        });
      }

      if (serviceProvider === PermutiveServiceProvider.GoogleCloudStorage) {
        try {
          const reader = new FileReader();
          reader.onloadend = () => {
            const credentialsJSON = JSON.parse(reader?.result as string);
            const credentials = JSON.stringify(credentialsJSON, null, 4);
            if (!credentials) {
              throw new Error("Credentials file is not valid");
            }
            submitFormHandler({
              input: {
                permutive: {
                  gcs: {
                    bucketName: bucketName.trim(),
                    credentials,
                    objectName: objectName.trim(),
                  },
                  ...permutiveCommonFields,
                },
              },
            });
          };
          reader.readAsText(credentialsFile);
        } catch (error) {
          throw new Error(error as string);
        }
      }
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <FormProvider {...form}>
        <Stack>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.apiKey"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.apiKey;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>Permutive API Key</FormLabel>
                  <Input
                    placeholder="Example: e26272e-ab1e-4254-b35a-3d225f9dca56"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.segmentName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.segmentName;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>Segment name</FormLabel>
                  <Input placeholder="Example: Data Clean Room" {...field} />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.segmentCode"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.segmentCode;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>Segment code</FormLabel>
                  <Input placeholder="Example: 1234" {...field} />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.importId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.importId;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>
                    <>
                      Import ID
                      <InfoTooltip
                        tooltip={
                          <>
                            Please check the{" "}
                            <a
                              href={
                                "https://support.permutive.com/hc/en-us/articles/360012495920-Audience-Import-Send-Segments-to-Permutive"
                              }
                              rel="noreferrer"
                              style={{ color: "inherit" }}
                              target="_blank"
                            >
                              Permutive documentation
                            </a>{" "}
                            for more details about importing.
                          </>
                        }
                      />
                    </>
                  </FormLabel>
                  <Input
                    placeholder="Example: ffedc3c9-d8fd-48f2-9719-12bfa83eb30d8"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.objectName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.objectName;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>Object name</FormLabel>
                  <Input placeholder="Example: my-object-name" {...field} />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.serviceProvider"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.serviceProvider;
              const withError = !isEmpty(fieldError);
              return (
                <FormControl error={withError}>
                  <FormLabel>Cloud service Provider</FormLabel>
                  <Select
                    renderValue={(option) => {
                      if (!option) {
                        return null;
                      }
                      return (
                        <Fragment>
                          <ListItemDecorator>
                            <ExternalConnectionsIcon
                              connectionType={
                                option.value as unknown as DataTargetType
                              }
                            />
                          </ListItemDecorator>
                          {dataTargetTypePresentation.get(
                            option.value as unknown as DataTargetType
                          )}
                        </Fragment>
                      );
                    }}
                    slotProps={{
                      listbox: { sx: { "--ListItemDecorator-size": "32px" } },
                    }}
                    sx={{ "--ListItemDecorator-size": "32px" }}
                    {...field}
                    onChange={(event, value) => field.onChange(value)}
                  >
                    {Object.values(PermutiveServiceProvider).map((value) => (
                      <Option
                        key={value}
                        label={dataTargetTypePresentation.get(
                          value as unknown as DataTargetType
                        )}
                        value={value}
                      >
                        <ListItemDecorator>
                          <ExternalConnectionsIcon
                            connectionType={value as unknown as DataTargetType}
                          />
                        </ListItemDecorator>
                        {dataTargetTypePresentation.get(
                          value as unknown as DataTargetType
                        )}
                      </Option>
                    ))}
                  </Select>
                  {withError && (
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  )}
                </FormControl>
              );
            }}
          />
          {cloudProviderValue ===
            PermutiveServiceProvider.GoogleCloudStorage && (
            <GoogleCloudStorageFormBucketField />
          )}
          {cloudProviderValue === PermutiveServiceProvider.S3 && (
            <S3BucketFormConfigurationFields />
          )}
          {cloudProviderValue === PermutiveServiceProvider.S3 && (
            <S3BucketFormCredentials />
          )}
          {cloudProviderValue ===
            PermutiveServiceProvider.GoogleCloudStorage && (
            <GoogleCloudStorageFormCredentials />
          )}
        </Stack>
      </FormProvider>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button onClick={handlePreviousStepClick}>Back</Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="solid"
        >
          Export
        </Button>
      </Box>
    </Box>
  );
};

PermutiveForm.displayName = "PermutiveForm";

export default memo(PermutiveForm);
