import { useAuth0 } from "@auth0/auth0-react";
import { usePublishedMediaInsightsDcrOrganizationsQuery } from "@decentriq/graphql/dist/hooks";
import {
  type Organization,
  type TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import {
  type media_insights_response,
  type MediaInsightsComputeLatest,
} from "ddc";
import { useMemo } from "react";
import { isNormalizedEmailIncluded } from "features/legacyMediaDataRoom/utils";
import { RawSupportedFeatures } from "features/MediaInsightsDcr/models";
import { RawMediaDataRoomFeatures } from "models";
import usePublishedMediaInsightsDcrDefinition from "../usePublishedMediaInsightsDcrDefinition";

interface PublishedMediaInsightsDcrQueryHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  isDeactivated: boolean;
  isStopped: boolean;
}

export interface PublishedMediaInsightsDcrQueryHookResult {
  definition: MediaInsightsComputeLatest;
  rawMediaInsightsDcr: media_insights_response.MediaInsightsDcr;
  loading: boolean;
  dataRoomId: string;
  driverAttestationHash: string;
  hasDataPartner: boolean;
  isAdvertiser: boolean;
  isAgency: boolean;
  isDataPartner: boolean;
  isDeactivated: boolean;
  isPublisher: boolean;
  isStopped: boolean;
  features: {
    retargeting: boolean;
    insights: boolean;
    lookalike: boolean;
    exclusionTargeting: boolean;
  };
  isObserver: boolean;
  publisherOrganization:
    | Pick<
        Organization,
        | "logo"
        | "__typename"
        | "id"
        | "name"
        | "enableExtendedLookalikeQualityStatistics"
      >
    | undefined;
  advertiserOrganization:
    | Pick<Organization, "logo" | "__typename" | "id" | "name">
    | undefined;
  supportedFeatures: {
    enableAdvertiserAudienceDownload: boolean;
    performanceReportEnabled: boolean;
    hideAbsoluteValues: boolean;
    enableExtendedLalQualityStats: boolean;
  };
  matchingIdFormat: TableColumnFormatType;
  hashMatchingIdWith: TableColumnHashingAlgorithm | null;
}

const usePublishedMediaInsightsDcrQuery = ({
  dataRoomId,
  driverAttestationHash,
  isDeactivated,
  isStopped,
}: PublishedMediaInsightsDcrQueryHookPayload): PublishedMediaInsightsDcrQueryHookResult => {
  const { user = {} } = useAuth0();
  const { email: currentUserEmail = "" } = user || {};
  const { data: dataRoomDefinition, isLoading: isDataRoomDefinitionLoading } =
    usePublishedMediaInsightsDcrDefinition({
      queryKeyPrefix: ["mi"],
    });
  const {
    mainAdvertiserEmail,
    mainPublisherEmail,
    publisherEmails: publisherUserEmails = [],
    advertiserEmails: advertiserUserEmails = [],
    agencyEmails: agencyUserEmails = [],
    observerEmails: observerUserEmails = [],
    dataPartnerEmails: dataPartnerUserEmails = [],
    hashMatchingIdWith,
    matchingIdFormat,
  } = dataRoomDefinition?.definition ?? {};
  const {
    hasDataPartner = false,
    isAdvertiser = false,
    isAgency = false,
    isDataPartner = false,
    isObserver = false,
    isPublisher = true,
  } = useMemo(
    () => ({
      hasDataPartner:
        dataPartnerUserEmails !== null && dataPartnerUserEmails.length > 0,
      isAdvertiser: isNormalizedEmailIncluded(
        advertiserUserEmails,
        currentUserEmail
      ),
      isAgency: isNormalizedEmailIncluded(agencyUserEmails, currentUserEmail),
      isDataPartner: isNormalizedEmailIncluded(
        dataPartnerUserEmails || [],
        currentUserEmail
      ),
      isObserver: isNormalizedEmailIncluded(
        observerUserEmails,
        currentUserEmail
      ),
      isPublisher: isNormalizedEmailIncluded(
        publisherUserEmails,
        currentUserEmail
      ),
    }),
    [
      advertiserUserEmails,
      agencyUserEmails,
      currentUserEmail,
      dataPartnerUserEmails,
      observerUserEmails,
      publisherUserEmails,
    ]
  );
  // Backend query
  const { data: organizationsData, loading: isOrganisationsDataLoading } =
    usePublishedMediaInsightsDcrOrganizationsQuery({
      skip: !mainAdvertiserEmail || !mainPublisherEmail,
      variables: {
        advertiserEmail: mainAdvertiserEmail!,
        publisherEmail: mainPublisherEmail!,
      },
    });

  const publishedMediaDataRoomContextValue = useMemo(
    (): PublishedMediaInsightsDcrQueryHookResult => ({
      advertiserOrganization: {
        ...organizationsData?.advertiserOrganization?.organization!,
        logo: organizationsData?.advertiserOrganization?.organizationLogo,
      },
      dataRoomId,
      definition: dataRoomDefinition?.definition!,
      driverAttestationHash,
      features: {
        exclusionTargeting:
          dataRoomDefinition?.rawMediaInsightsDcr?.v0?.features?.includes(
            RawMediaDataRoomFeatures.ExclusionTargeting
          ) ?? false,
        insights:
          dataRoomDefinition?.rawMediaInsightsDcr?.v0?.features?.includes(
            RawMediaDataRoomFeatures.Insights
          ) ?? false,
        lookalike:
          dataRoomDefinition?.rawMediaInsightsDcr?.v0?.features?.includes(
            RawMediaDataRoomFeatures.Lookalike
          ) ?? false,
        retargeting:
          dataRoomDefinition?.rawMediaInsightsDcr?.v0?.features?.includes(
            RawMediaDataRoomFeatures.Retargeting
          ) ?? false,
      },
      hasDataPartner,
      hashMatchingIdWith: (hashMatchingIdWith ||
        null) as TableColumnHashingAlgorithm | null,
      isAdvertiser,
      isAgency,
      isDataPartner,
      isDeactivated,
      isObserver,
      isPublisher,
      isStopped,
      loading: isDataRoomDefinitionLoading || isOrganisationsDataLoading,
      matchingIdFormat: matchingIdFormat as TableColumnFormatType,
      publisherOrganization: {
        ...organizationsData?.publisherOrganization?.organization!,
        logo: organizationsData?.publisherOrganization?.organizationLogo,
      },
      rawMediaInsightsDcr: dataRoomDefinition?.rawMediaInsightsDcr!,
      supportedFeatures: {
        enableAdvertiserAudienceDownload:
          dataRoomDefinition?.supportedFeatures?.includes(
            RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD
          ) ?? false,
        enableExtendedLalQualityStats:
          dataRoomDefinition?.supportedFeatures?.includes(
            RawSupportedFeatures.ENABLE_EXTENDED_LAL_QUALITY_STATS
          ) ?? false,
        hideAbsoluteValues:
          dataRoomDefinition?.supportedFeatures?.includes(
            RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS
          ) ?? false,
        performanceReportEnabled:
          dataRoomDefinition?.supportedFeatures?.includes(
            RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION
          ) ?? false,
      },
    }),
    [
      organizationsData?.advertiserOrganization?.organization,
      organizationsData?.advertiserOrganization?.organizationLogo,
      organizationsData?.publisherOrganization?.organization,
      organizationsData?.publisherOrganization?.organizationLogo,
      dataRoomId,
      dataRoomDefinition?.definition,
      dataRoomDefinition?.rawMediaInsightsDcr,
      dataRoomDefinition?.supportedFeatures,
      driverAttestationHash,
      hasDataPartner,
      hashMatchingIdWith,
      isAdvertiser,
      isAgency,
      isDataPartner,
      isDeactivated,
      isObserver,
      isPublisher,
      isStopped,
      isDataRoomDefinitionLoading,
      isOrganisationsDataLoading,
      matchingIdFormat,
    ]
  );
  return publishedMediaDataRoomContextValue;
};

export default usePublishedMediaInsightsDcrQuery;
