// TODO: Fix joy migration
import { useCreateDatasetImportMutation } from "@decentriq/graphql/dist/hooks";
import {
  type CreateDatasetImportMutation,
  type CreateDatasetImportMutationVariables,
  DataSourceType,
} from "@decentriq/graphql/dist/types";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { styled } from "@mui/material";
import { memo, useCallback, useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  dataSourceTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { ExternalConnectionType } from "features/datasets/components/ExternalConnections";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  AzureFormControls,
  ExternalDataImportStatus,
  GoogleCloudStorageFormControls,
  PermutiveFormControls,
  S3BucketFormControls,
  SalesforceFormControls,
  SnowflakeFormControls,
} from "./components";
import { ImportExternalDataWrapper, useImportExternalData } from "./contexts";
import { getDefaultValues, schema, type SchemaType } from "./models";
import { EXTERNAL_DATA_IMPORT_STEPS } from "./types";

const FORM_ID = "import-extrernal-dataset-form";

type ImportExternalDataDialogProps = {
  open: boolean;
  restartImport: () => void;
  onCancel: () => void;
};

const ImportExternalDataDialogWrapper: React.FC<
  ImportExternalDataDialogProps & { selectedDataSource?: DataSourceType }
> = ({ selectedDataSource, ...props }) => {
  if (!props.open) {
    return null;
  }
  return (
    <ImportExternalDataWrapper defaultSelectedDataSource={selectedDataSource}>
      <ImportExternalDataDialog {...props} />
    </ImportExternalDataWrapper>
  );
};

const ImportStatusActionsWrapper = styled(DialogActions)({
  display: "flex",
  justifyContent: "space-between",
});

const ImportStatusStatusWrapper = styled(DialogContent)({
  alignItems: "center",
  display: "flex",
  flexDirection: "column",
});

const ImportExternalDataDialog: React.FC<ImportExternalDataDialogProps> = memo(
  ({ open, restartImport, onCancel }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const {
      importStep,
      selectedDataSource,
      setDatasetImportId,
      setIsImportCreated,
      setImportStep,
      setDatasetImportError,
    } = useImportExternalData();
    const [createDatasetImportMutation] = useCreateDatasetImportMutation();
    const handleCreate = useCallback(
      (createDatasetImportVariables: CreateDatasetImportMutationVariables) => {
        createDatasetImportMutation({
          onCompleted: ({
            createDatasetImport,
          }: CreateDatasetImportMutation) => {
            setDatasetImportId(createDatasetImport?.dataConnectorJob.id);
            setTimeout(() => setIsImportCreated(true), 3000);
          },
          onError: (error) => {
            setDatasetImportError(error?.message);
            enqueueSnackbar(
              ...mapDraftDataRoomErrorToSnackbar(
                error,
                "The importing process could not get started. Please try again by refreshing the page."
              )
            );
          },
          variables: createDatasetImportVariables,
        });
        setImportStep(EXTERNAL_DATA_IMPORT_STEPS.IMPORT_STATUS);
      },
      [
        createDatasetImportMutation,
        setImportStep,
        setDatasetImportId,
        setIsImportCreated,
        setDatasetImportError,
        enqueueSnackbar,
      ]
    );
    const handleCancel = useCallback(() => {
      if (restartImport) {
        restartImport();
        return;
      }
      setImportStep(EXTERNAL_DATA_IMPORT_STEPS.SELECT_SOURCE);
    }, [setImportStep, restartImport]);
    const handleFormSubmit = useCallback(
      async (formValues: SchemaType) => {
        // eslint-disable-next-line no-console
        console.log({ formValues });
        /*
        const {
          configuration: {
            blobName = "",
            storageAccount = "",
            storageContainer = "",
          } = {},
          credentials: { sasToken = "" } = {},
          datasetName = "",
        } = formValues;
        return await handleCreate({
          input: {
            azure: {
              credentials: {
                blobName: blobName.trim(),
                sasToken: sasToken.trim(),
                storageAccount: storageAccount.trim(),
                storageContainer: storageContainer.trim(),
              },
            },
            datasetName: datasetName?.trim() || blobName?.trim(),
          },
        });
        */
      },
      [handleCreate]
    );
    const [disabled, setDisabled] = useState(false);
    const form = useForm({
      defaultValues: getDefaultValues(selectedDataSource),
      disabled,
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(schema),
    });
    const { handleSubmit, reset, formState } = form;
    const { isSubmitting, isDirty, errors } = formState;
    // eslint-disable-next-line no-console
    console.log({ errors });
    // TODO: Fix this weirdness
    const onBackClick = useCallback(() => {
      reset();
      handleCancel();
    }, [reset, handleCancel]);
    useEffect(() => {
      setDisabled(isSubmitting);
    }, [isSubmitting]);
    useEffect(() => {
      if (!open) {
        reset();
      }
    }, [open, reset]);
    return (
      <FormProvider {...form}>
        <form id={FORM_ID} onSubmit={handleSubmit(handleFormSubmit)}>
          <Drawer
            anchor="right"
            disableEscapeKeyDown={isDirty}
            open={open}
            slotProps={{
              content: {
                sx: {
                  bgcolor: "transparent",
                  boxShadow: "none",
                  p: { md: 3, sm: 0 },
                },
              },
            }}
          >
            <ModalDialog
              sx={{
                height: "100%",
                maxHeight: "100%",
                maxWidth: "100%",
                minWidth: "100%",
                position: "relative",
                width: "100%",
              }}
            >
              <DialogTitle>
                <Box>
                  <Typography level="title-sm">
                    Import dataset from external source
                  </Typography>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      gap: "0.25rem",
                    }}
                  >
                    <ExternalConnectionsIcon
                      connectionType={selectedDataSource}
                      size={ExternalConnectionsIconSize.sm}
                    />
                    {dataSourceTypePresentation.get(selectedDataSource)}
                  </Box>
                </Box>
              </DialogTitle>
              <Divider />
              {importStep === EXTERNAL_DATA_IMPORT_STEPS.SOURCE_FORM ? (
                <DialogContent>
                  {
                    {
                      [DataSourceType.Azure]: (
                        <AzureFormControls
                          type={ExternalConnectionType.IMPORT}
                        />
                      ),
                      [DataSourceType.Compute]: null,
                      [DataSourceType.GoogleCloudStorage]: (
                        <GoogleCloudStorageFormControls
                          type={ExternalConnectionType.IMPORT}
                        />
                      ),
                      [DataSourceType.Local]: null,
                      [DataSourceType.Permutive]: <PermutiveFormControls />,
                      [DataSourceType.S3]: (
                        <S3BucketFormControls
                          type={ExternalConnectionType.IMPORT}
                        />
                      ),
                      [DataSourceType.Salesforce]: (
                        <SalesforceFormControls
                          type={ExternalConnectionType.IMPORT}
                        />
                      ),
                      [DataSourceType.Snowflake]: <SnowflakeFormControls />,
                    }[selectedDataSource]
                  }
                </DialogContent>
              ) : null}
              {importStep === EXTERNAL_DATA_IMPORT_STEPS.IMPORT_STATUS ? (
                <ExternalDataImportStatus
                  ActionsWrapper={ImportStatusActionsWrapper}
                  StatusWrapper={ImportStatusStatusWrapper}
                  onClose={onCancel}
                />
              ) : null}
              <Divider />
              <DialogActions>
                <Button
                  disabled={disabled}
                  form={FORM_ID}
                  onClick={onBackClick}
                  type="reset"
                >
                  Back
                </Button>
                <Button
                  color="primary"
                  disabled={disabled}
                  form={FORM_ID}
                  loading={isSubmitting}
                  loadingPosition="start"
                  type="submit"
                  variant="solid"
                >
                  Import
                </Button>
              </DialogActions>
            </ModalDialog>
          </Drawer>
        </form>
      </FormProvider>
    );
  }
);
ImportExternalDataDialog.displayName = "ImportExternalDataDialog";

export default ImportExternalDataDialogWrapper;
