import { DqTable } from "@decentriq/components";
import { type MatchingColumnFormat } from "@decentriq/graphql/dist/types";
import {
  faCircleExclamation,
  faUpload,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Chip, CircularProgress, Tooltip } from "@mui/joy";
import { format } from "date-fns";
import { type data_lab, data_lab_schema } from "ddc";
import orderBy from "lodash/orderBy";
import { type MRT_ColumnDef } from "material-react-table";
import { useCallback, useMemo } from "react";
import { useDataLabsContext } from "features/dataLabs";
import {
  type DataLab,
  matchingIdTypeAndHashingAlgorithmPresentation,
} from "features/dataLabs/models";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/mediaDataRoom/contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";
import usePublisherDataNodeActions from "../hooks/usePublisherDataNodeActions";

interface DataLabConnectionTableProps {
  dataRoomId: string;
  retrieveDatasets: () => Promise<void>;
  onCancel: () => void;
}

const DataLabConnectionTable: React.FC<DataLabConnectionTableProps> = ({
  retrieveDatasets,
  dataRoomId,
  onCancel,
}) => {
  const { session } = useMediaInsightsDcrData();
  const { rawMediaInsightsDcr } = usePublishedMediaInsightsDcr();
  const {
    dataLabs: { data: dataLabsData, loading },
  } = useDataLabsContext();
  const { connectDataLab, connecting, dataLabId } = usePublisherDataNodeActions(
    { retrieveDatasets }
  );
  const handleConnectDataLab = useCallback(
    async (dataLab: DataLab) => {
      await connectDataLab(dataLab);
      onCancel();
    },
    [connectDataLab, onCancel]
  );
  const dateFormat = "dd.MM.yyy, HH:mm";
  const dataLabs = useMemo(
    () =>
      orderBy(
        dataLabsData || [],
        ({ updatedAt }) => new Date(updatedAt),
        "desc"
      ),
    [dataLabsData]
  );
  const dataLabsColumnDef: MRT_ColumnDef<DataLab>[] = useMemo(
    () => [
      {
        accessorKey: "name",
        header: "Name",
        id: "name",
      },
      {
        Cell: ({ cell }) => {
          const createdAt = cell.getValue<string>();
          return createdAt ? format(new Date(createdAt), dateFormat) : "—";
        },
        accessorKey: "updatedAt",
        header: "Last modified",
        id: "updatedAt",
      },
      {
        Cell: ({ cell }) => {
          const matchingIdFormat = cell.getValue<MatchingColumnFormat>();
          const matchingIdHashingAlgorithm =
            cell.row.original.matchingIdHashingAlgorithm;
          return matchingIdTypeAndHashingAlgorithmPresentation({
            matchingIdFormat,
            matchingIdHashingAlgorithm,
          });
        },
        accessorKey: "matchingIdFormat",
        header: "Matching ID type",
        id: "matchingIdFormat",
      },
    ],
    []
  );
  if (loading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="5rem"
        width="100%"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "2.5rem" }} />
      </Box>
    );
  }
  return (
    <DqTable
      columns={dataLabsColumnDef}
      data={dataLabs}
      displayColumnDefOptions={{
        "mrt-row-actions": {
          size: 160,
        },
      }}
      enableRowActions={true}
      localization={{
        actions: "",
        noRecordsToDisplay: "No datalabs found",
      }}
      muiTablePaperProps={{
        sx: {
          display: "flex",
          flex: 1,
          flexDirection: "column",
          overflow: "hidden",
          width: "100%",
        },
      }}
      renderRowActions={({ row }) => {
        const validated = row.original.isValidated;
        const canBeProvisioned =
          session?.compiler?.dataLab.isCompatibleWithMediaInsightsDcr(
            data_lab_schema.default.parse(
              JSON.parse(row.original.highLevelRepresentationAsString)
            ) as data_lab.DataLab,
            rawMediaInsightsDcr
          ) ?? false;
        return (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            {validated ? (
              canBeProvisioned ? (
                <Button
                  disabled={connecting && dataLabId !== row.original.id}
                  loading={connecting && dataLabId === row.original.id}
                  loadingPosition="end"
                  onClick={() => handleConnectDataLab(row.original)}
                  startDecorator={
                    <FontAwesomeIcon fixedWidth={true} icon={faUpload} />
                  }
                  style={{ marginLeft: "16px" }}
                >
                  Provision datalab
                </Button>
              ) : (
                <Tooltip
                  placement="top-start"
                  title="This datalab either has an incompatible matching ID or does not contain all required datasets for this data clean room."
                >
                  <Chip
                    color="warning"
                    startDecorator={
                      <FontAwesomeIcon icon={faCircleExclamation} />
                    }
                    variant="solid"
                  >
                    Incompatible
                  </Chip>
                </Tooltip>
              )
            ) : (
              <Tooltip
                placement="top-start"
                title="To validate it, open the datalab, review the provisioned datasets and compute the statistics."
              >
                <Chip
                  color="warning"
                  startDecorator={
                    <FontAwesomeIcon icon={faCircleExclamation} />
                  }
                  variant="solid"
                >
                  Not validated
                </Chip>
              </Tooltip>
            )}
          </Box>
        );
      }}
    />
  );
};

DataLabConnectionTable.displayName = "DataLabConnectionTable";

export default DataLabConnectionTable;
