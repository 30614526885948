// TODO: Fix joy migration
import { InfoTooltip } from "@decentriq/components";
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ExternalConnectionCredentialsLabel } from "features/datasets/components/ExternalConnections";

const GoogleCloudStorageFormCredentials: React.FC = () => {
  const { control } = useFormContext();
  return (
    <>
      <ExternalConnectionCredentialsLabel />
      <Controller
        control={control}
        name="credentials.credentialsFile"
        render={({ field: { value, onChange, ...restField }, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.credentials?.credentialsFile;
          return (
            <FormControl error={!isEmpty(fieldError)}>
              <FormLabel>
                Service account key in JSON format
                <InfoTooltip
                  tooltip={
                    <>
                      Please{" "}
                      <a
                        href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                        rel="noreferrer"
                        style={{ color: "inherit" }}
                        target="_blank"
                      >
                        follow the instructions
                      </a>{" "}
                      to obtain a service account key
                    </>
                  }
                />
              </FormLabel>
              <Input
                {...restField}
                slotProps={{
                  input: {
                    accept: "application/JSON",
                    multiple: false,
                    onChange: (event: React.ChangeEvent<HTMLInputElement>) =>
                      onChange((event?.target?.files || [])[0]),
                    type: "file",
                  },
                  root: { sx: { alignItems: "center" } },
                }}
                value={value?.fileName}
              />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
};

GoogleCloudStorageFormCredentials.displayName =
  "GoogleCloudStorageFormCredentials";

export default memo(GoogleCloudStorageFormCredentials);
