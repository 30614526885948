import {
  type DataSourceType,
  PermutiveServiceProvider,
} from "@decentriq/graphql/dist/types";
import { faFileLines } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ListItemDecorator,
  Option,
  Select,
  Stack,
  Typography,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { Fragment, memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { ExternalConnectionsIcon } from "features/datasets";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
} from "features/datasets/components/ExternalConnections";
import { dataSourceTypePresentation } from "features/datasets/models";
import { type ImportExternalDataFormProps } from "../../../../types";
import {
  GoogleCloudStorageFormBucketField,
  GoogleCloudStorageFormCredentials,
} from "../GoogleCloudStorageForm/components";
import S3BucketFormConfigurationFields from "../S3BucketForm/components/S3BucketFormConfigurationFields";
import S3BucketFormCredentials from "../S3BucketForm/components/S3BucketFormCredentials";

type PermutiveFormProps = ImportExternalDataFormProps & {
  ActionsWrapper?: React.ComponentType;
  FormWrapper?: React.ComponentType;
};

const PermutiveFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    bucketName: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.GoogleCloudStorage,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Bucket name is required"),
      }),
    region: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.S3,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Region is required"),
      }),
    serviceProvider: yup
      .mixed()
      .oneOf(Object.values(PermutiveServiceProvider))
      .required("Service provider is required"),
    url: yup
      .string()
      .trim()
      .when("serviceProvider", {
        is: (serviceProvider: PermutiveServiceProvider) =>
          serviceProvider === PermutiveServiceProvider.S3,
        otherwise: () => yup.string(),
        then: () => yup.string().trim().required("Bucket URL is required"),
      }),
  }),
  credentials: yup.object().when("configuration.serviceProvider", {
    is: (serviceProvider: PermutiveServiceProvider) =>
      serviceProvider === PermutiveServiceProvider.S3,
    otherwise: () =>
      yup.object().shape({
        accessKey: yup.string(),
        credentialsFile: yup.mixed().required("Credentials file is required"),
        secretKey: yup.string(),
      }),
    then: () =>
      yup.object().shape({
        accessKey: yup.string().trim().required("Access key is required"),
        credentialsFile: yup.mixed(),
        secretKey: yup.string().trim().required("Secret Key is required"),
      }),
  }),
  datasets: yup.object({
    demographicsDatasetName: yup.string(),
    matchingDatasetName: yup
      .string()
      .trim()
      .required("Matching dataset name is required"),
    segmentsDatasetName: yup
      .string()
      .trim()
      .required("Segments dataset name is required"),
  }),
});

type PermutiveFormValues = yup.InferType<typeof PermutiveFormValidationSchema>;

const PermutiveForm: React.FC<PermutiveFormProps> = ({
  onSubmit,
  onCancel,
  ActionsWrapper = ExternalConnectionActionsWrapper,
  FormWrapper = Box,
}) => {
  const form = useForm({
    defaultValues: {
      configuration: {
        bucketName: "", // Cloud Storage configuration
        region: "", // S3 bucket configuration
        serviceProvider: PermutiveServiceProvider.GoogleCloudStorage,
        url: "", // S3 bucket configuration
      },
      credentials: {
        accessKey: "", // S3 bucket credentials
        credentialsFile: undefined, // Cloud Storage Credentials
        secretKey: "", // S3 bucket credentials
      },
      datasets: {
        demographicsDatasetName: "",
        matchingDatasetName: "matching.csv",
        segmentsDatasetName: "segments.csv",
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(PermutiveFormValidationSchema),
  });
  const { control, handleSubmit, reset, watch } = form;

  const cloudProviderValue = watch("configuration.serviceProvider");

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: PermutiveFormValues) => {
      const {
        configuration: {
          serviceProvider,
          region = "",
          url = "",
          bucketName = "",
        } = {},
        credentials: { credentialsFile, accessKey = "", secretKey = "" } = {},
        datasets,
      } = formValues;

      if (serviceProvider === PermutiveServiceProvider.S3) {
        onSubmit({
          input: {
            datasetName: "",
            permutive: {
              configuration: {
                aws: { bucket: url.trim(), region: region.trim() },
              },
              credentials: {
                aws: {
                  accessKey: accessKey.trim(),
                  secretKey: secretKey.trim(),
                },
              },
              datasets,
              serviceProvider,
            },
          },
        });
      }

      if (serviceProvider === PermutiveServiceProvider.GoogleCloudStorage) {
        try {
          const reader = new FileReader();
          reader.onloadend = () => {
            const credentialsJSON = JSON.parse(reader?.result as string);
            const credentials = JSON.stringify(credentialsJSON, null, 4);
            if (!credentials) {
              throw new Error("Credentials file is not valid");
            }
            onSubmit({
              input: {
                datasetName: "",
                permutive: {
                  configuration: { gcs: { bucketName: bucketName.trim() } },
                  credentials: {
                    gcs: {
                      credentials,
                    },
                  },
                  datasets,
                  serviceProvider,
                },
              },
            });
          };
          reader.readAsText(credentialsFile);
        } catch (error) {
          throw new Error(error as string);
        }
      }
      reset();
    },
    [reset, onSubmit]
  );

  return (
    <>
      <FormWrapper>
        <FormProvider {...form}>
          <Stack>
            <ExternalConnectionConfigurationLabel />
            <Controller
              control={control}
              name="configuration.serviceProvider"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.serviceProvider;
                const withError = !isEmpty(fieldError);
                return (
                  <FormControl error={withError}>
                    <FormLabel>Cloud service Provider</FormLabel>
                    <Select
                      renderValue={(option) => {
                        if (!option) {
                          return null;
                        }
                        return (
                          <Fragment>
                            <ListItemDecorator>
                              <ExternalConnectionsIcon
                                connectionType={
                                  option.value as unknown as DataSourceType
                                }
                              />
                            </ListItemDecorator>
                            {dataSourceTypePresentation.get(
                              option.value as unknown as DataSourceType
                            )}
                          </Fragment>
                        );
                      }}
                      slotProps={{
                        listbox: { sx: { "--ListItemDecorator-size": "32px" } },
                      }}
                      sx={{ "--ListItemDecorator-size": "32px" }}
                      {...field}
                      onChange={(event, value) => field.onChange(value)}
                    >
                      {Object.values(PermutiveServiceProvider).map((value) => (
                        <Option
                          key={value}
                          label={dataSourceTypePresentation.get(
                            value as unknown as DataSourceType
                          )}
                          value={value}
                        >
                          <ListItemDecorator>
                            <ExternalConnectionsIcon
                              connectionType={
                                value as unknown as DataSourceType
                              }
                            />
                          </ListItemDecorator>
                          {dataSourceTypePresentation.get(
                            value as unknown as DataSourceType
                          )}
                        </Option>
                      ))}
                    </Select>
                    {withError && (
                      <FormHelperText>{fieldError?.message}</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            />
            {cloudProviderValue ===
              PermutiveServiceProvider.GoogleCloudStorage && (
              <GoogleCloudStorageFormBucketField />
            )}
            {cloudProviderValue === PermutiveServiceProvider.S3 && (
              <S3BucketFormConfigurationFields />
            )}
            <Typography>
              <FontAwesomeIcon
                icon={faFileLines}
                style={{ marginRight: 4, marginTop: 2, opacity: 0.5 }}
              />
              Publisher Datasets
            </Typography>
            <Controller
              control={control}
              name="datasets.matchingDatasetName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.datasets?.matchingDatasetName;
                return (
                  <FormControl error={!isEmpty(fieldError)}>
                    <FormLabel>Matching dataset</FormLabel>
                    <Input placeholder="Example: matching.csv" {...field} />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="datasets.segmentsDatasetName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.datasets?.segmentsDatasetName;
                return (
                  <FormControl error={!isEmpty(fieldError)}>
                    <FormLabel>Segments dataset</FormLabel>
                    <Input placeholder="Example: segments.csv" {...field} />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="datasets.demographicsDatasetName"
              render={({ field }) => {
                return (
                  <FormControl>
                    <FormLabel>Demographics dataset (optional)</FormLabel>
                    <Input placeholder="Example: demographics.csv" {...field} />
                  </FormControl>
                );
              }}
            />
            {cloudProviderValue === PermutiveServiceProvider.S3 && (
              <S3BucketFormCredentials />
            )}
            {cloudProviderValue ===
              PermutiveServiceProvider.GoogleCloudStorage && (
              <GoogleCloudStorageFormCredentials />
            )}
          </Stack>
        </FormProvider>
      </FormWrapper>
      <ActionsWrapper>
        <Button onClick={handlePreviousStepClick}>Back</Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="solid"
        >
          Import
        </Button>
      </ActionsWrapper>
    </>
  );
};

PermutiveForm.displayName = "PermutiveForm";

export default memo(PermutiveForm);
