import { Button, Grid } from "@mui/material";
import { memo } from "react";
import {
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator";
import {
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
} from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator/components";

const RulesBasedAdvertiserAudienceGeneratorConfigurationStep = memo(() => {
  const { handleBackStep, handleNextStep, isFirstStep, cancelGeneration } =
    useAdvertiserAudienceGenerator();
  const shouldCancelGenerationOnBack = isFirstStep(
    AdvertiserAudienceGeneratorStep.CONFIGURATION
  );
  return (
    <>
      <Grid container={true} item={true} mb={8} xs={12}>
        <Grid item={true} xs={7}>
          <AudienceGeneratorStepSectionTitle>
            1. Rule-based audience setup
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Set up your audience by defining specific criteria and parameters
            for precise targeting.
          </AudienceGeneratorStepSectionDescription>
        </Grid>
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button
          color="inherit"
          onClick={
            shouldCancelGenerationOnBack ? cancelGeneration : handleBackStep
          }
          sx={{ mr: 1 }}
          variant="text"
        >
          {shouldCancelGenerationOnBack ? "Cancel" : "Back"}
        </Button>
        <Button color="primary" onClick={handleNextStep} variant="contained">
          Continue
        </Button>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

RulesBasedAdvertiserAudienceGeneratorConfigurationStep.displayName =
  "RulesBasedAdvertiserAudienceGeneratorConfigurationStep";

export default RulesBasedAdvertiserAudienceGeneratorConfigurationStep;
