import { faFileImport } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@mui/joy";
import { memo } from "react";
import ImportDataDialog from "../ImportDataDialog/ImportDataDialog";

const ImportDataButton: React.FC = () => (
  <ImportDataDialog
    renderTrigger={(open) => (
      <Button
        color="primary"
        onClick={open}
        startDecorator={
          <FontAwesomeIcon fixedWidth={true} icon={faFileImport} />
        }
        variant="solid"
      >
        Import dataset
      </Button>
    )}
  />
);

ImportDataButton.displayName = "ImportDataButton";

export default memo(ImportDataButton);
