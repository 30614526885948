// TODO: Fix joy migration
import { InfoTooltip } from "@decentriq/components";
import { Typography } from "@mui/joy";
import { memo, useMemo } from "react";
import { useLookalikeAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/containers/LookalikeAdvertiserAudienceGenerator";

const QualityScore: React.FC = () => {
  const { selectedLookalikeAudience, reach: selectedReach } =
    useLookalikeAdvertiserAudienceGenerator();

  // Detailed calculation description:
  // https://www.notion.so/decentriq/Re-Enable-Beta-Model-Quality-Viz-3-18-2-b49a6106201448aaac1d3c11568910b8?pvs=4#dd6bf187d4f74c4db7c18f4dcba677b0
  const qualityPerReachArray = useMemo(() => {
    const trueFalsePositivePairs: number[][] = (
      selectedLookalikeAudience?.filtered_audiences || []
    ).map(({ quality: { roc_curve } }) => [roc_curve?.tpr, roc_curve?.fpr]);
    const reachValues: number[] = (
      selectedLookalikeAudience?.filtered_audiences || []
    ).map(({ reach }) => reach);

    const normalizedPrecision = trueFalsePositivePairs.map(
      ([tpr, fpr]) => tpr / (tpr + fpr)
    );
    const maxNormalizedPrecision = Math.max(...normalizedPrecision);

    const offset = 0.5;
    const scaleFactor =
      maxNormalizedPrecision > offset
        ? (1 - offset) / (maxNormalizedPrecision - offset)
        : 1;

    // Calculate quality
    const quality = normalizedPrecision.map(
      (precision) => scaleFactor * (precision - offset) + offset
    );

    // Pair slider position with quality
    const qualityPerReachArray = reachValues.map((reach, index) => [
      quality[index],
      reach,
    ]);

    return qualityPerReachArray;
  }, [selectedLookalikeAudience]);

  const qualityScore = useMemo(() => {
    const [rawQualityScore = 0] =
      qualityPerReachArray.find(([, reach]) => reach === selectedReach) || [];
    return rawQualityScore === 1
      ? rawQualityScore * 10
      : (rawQualityScore * 10).toFixed(2);
  }, [selectedReach, qualityPerReachArray]);

  return (
    <>
      <Typography fontWeight={500} level="body-sm">
        Quality score
        <InfoTooltip tooltip="This is a weighted and normalized measure of model precision. We first calculate a measure of precision where the seed audience and the rest of the audience have equal weight: True Positive Rate / (True Positive Rate + False Positive Rate). It is normalized so that the highest observed precision is 10, and a 50% weighted precision is a 5. Lower precisions are rare and are not normalized." />
      </Typography>
      <Typography
        color="primary.main"
        fontSize={36}
        fontWeight="fontWeightSemiBold"
      >
        {`${qualityScore}/10`}
      </Typography>
    </>
  );
};

QualityScore.displayName = "QualityScore";

export default memo(QualityScore);
