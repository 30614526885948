// TODO: Fix joy migration
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ExternalConnectionCredentialsLabel } from "features/datasets/components/ExternalConnections";

const S3BucketFormCredentials: React.FC = () => {
  const { control } = useFormContext();
  return (
    <Box>
      <Stack>
        <ExternalConnectionCredentialsLabel />
        <Controller
          control={control}
          name="credentials.accessKey"
          render={({ field, formState }) => {
            const { errors } = formState;
            const fieldError = errors?.credentials?.accessKey;
            return (
              <FormControl error={!isEmpty(fieldError)} required={true}>
                <FormLabel>Access Key</FormLabel>
                <Input placeholder={`e.g. "5JG8C6HNDPQF7W2X3Y1Z"`} {...field} />
                <FormHelperText>{fieldError?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
        <Controller
          control={control}
          name="credentials.secretKey"
          render={({ field, formState }) => {
            const { errors } = formState;
            const fieldError = errors?.credentials?.secretKey;
            return (
              <FormControl error={!isEmpty(fieldError)} required={true}>
                <FormLabel>Secret Key</FormLabel>
                <Input
                  placeholder={`e.g. "F57yT3QaX2nD8jHr4KlP9cVs1W6zU0E2sY8hN4bM"`}
                  {...field}
                />
                <FormHelperText>{fieldError?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
      </Stack>
    </Box>
  );
};

S3BucketFormCredentials.displayName = "S3BucketFormCredentials";

export default memo(S3BucketFormCredentials);
