import { Box, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { ImportDataButton } from "features/datasets/components";
import { useDatasetImportExportWatcher } from "hooks";
import DatasetsList from "../DatasetsList/DatasetsList";
import ExternalConnectionsList from "../ExternalConnectionsList/ExternalConnectionsList";

export enum DatasetsTabs {
  DATASETS = "datasets",
  EXTERNAL = "external",
}

interface DatasetProps {
  activeTab: DatasetsTabs;
  baseUrl: string;
}

const Datasets: React.FC<DatasetProps> = ({ activeTab, baseUrl }) => {
  useDatasetImportExportWatcher();

  return (
    <Fragment>
      <Box
        sx={{
          backgroundColor: "background.bar",
          display: "flex",
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Typography level="title-md">
          <strong>Datasets</strong>
        </Typography>
        <ImportDataButton />
      </Box>
      <Tabs value={activeTab}>
        <Box>
          <Box
            sx={{
              backgroundColor: "background.bar",
              display: "flex",
              flex: 1,
              flexDirection: "column",
              overflow: "hidden",
            }}
          >
            <TabList>
              <Tab
                component={Link}
                data-testid="tab_datasets_datasets"
                to={`${baseUrl}/${DatasetsTabs.DATASETS}`}
                value={DatasetsTabs.DATASETS}
              >
                Datasets
              </Tab>
              <Tab
                component={Link}
                data-testid="tab_datasets_external"
                to={`${baseUrl}/${DatasetsTabs.EXTERNAL}`}
                value={DatasetsTabs.EXTERNAL}
              >
                Imports/Exports
              </Tab>
            </TabList>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flex: 1,
            flexDirection: "column",
            gap: 2,
            overflow: "hidden",
            padding: 2,
          }}
        >
          <TabPanel
            sx={{
              "&:not([hidden])": {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                p: 0,
              },
            }}
            value={DatasetsTabs.DATASETS}
          >
            <DatasetsList />
          </TabPanel>
          <TabPanel
            sx={{
              "&:not([hidden])": {
                display: "flex",
                flex: 1,
                flexDirection: "column",
                overflow: "auto",
                p: 0,
              },
            }}
            value={DatasetsTabs.EXTERNAL}
          >
            <ExternalConnectionsList />
          </TabPanel>
        </Box>
      </Tabs>
    </Fragment>
  );
};

export default Datasets;
