import { Stack } from "@mui/joy";
import { memo } from "react";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
} from "features/datasets/components/ExternalConnections";
import ControlledInput from "../ControlledInput/ControlledInput";

const SnowflakeFormControls: React.FC = memo(() => {
  return (
    <Stack>
      <ExternalConnectionConfigurationLabel />
      <ControlledInput
        label="Warehouse name"
        name="snowflake.sourceConfig.warehouseName"
        placeholder={`e.g. "DEV_WAREHOUSE"`}
      />
      <ControlledInput
        label="Database name"
        name="snowflake.sourceConfig.databaseName"
        placeholder={`e.g. "TEST_DATABASE"`}
      />
      <ControlledInput
        label="Schema name"
        name="snowflake.sourceConfig.schemaName"
        placeholder={`e.g. "TEST_SCHEMA"`}
      />
      <ControlledInput
        label="Table name"
        name="snowflake.sourceConfig.tableName"
        placeholder={`e.g. "DATA_TABLE"`}
      />
      <ControlledInput
        label="Stage name"
        name="snowflake.sourceConfig.stageName"
        placeholder={`e.g. "my_int_stage"`}
      />
      <ExternalConnectionCredentialsLabel />
      <ControlledInput
        label="Account ID"
        name="snowflake.credentials.accountId"
        placeholder={`e.g. "sdkreui-mt37045"`}
      />
      <ControlledInput
        label="Role"
        name="snowflake.credentials.role"
        placeholder={`e.g. "PUBLIC"`}
      />
      <ControlledInput
        label="Username"
        name="snowflake.credentials.username"
        placeholder={`e.g. "testuser"`}
      />
      <ControlledInput
        label="Password"
        name="snowflake.credentials.password"
        placeholder={`e.g. "Cfw3{?F^K46m(fj"`}
      />
    </Stack>
  );
});
SnowflakeFormControls.displayName = "SnowflakeFormControls";

export default SnowflakeFormControls;
