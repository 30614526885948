// TODO: Fix joy migration - this should be imported from @decentriq/theme
import { createDefaultTheme } from "@decentriq/theme";
import { faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  buttonClasses,
  dialogActionsClasses,
  dialogContentClasses,
  dialogTitleClasses,
  menuButtonClasses,
  selectClasses,
  tabClasses,
} from "@mui/joy";
import {
  CssVarsProvider as JoyCssVarsProvider,
  extendTheme,
} from "@mui/joy/styles";
import { type PaletteRange } from "@mui/joy/styles";
import {
  CssBaseline,
  GlobalStyles,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as materialExtendTheme,
  THEME_ID as MATERIAL_THEME_ID,
} from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { memo } from "react";

const indicator = <FontAwesomeIcon fixedWidth={true} icon={faAngleDown} />;

const indicatorActiveStyles = {
  transform: "none", // NOTE: "rotate(-180deg)" to be used eventually. Split-button's `IconButton` to use `className="...-indicator"` and the same styles?
};

interface StylesWrapperProps {
  children?: React.ReactNode;
}

declare module "@mui/joy/styles" {
  interface ColorPalettePropOverrides {
    secondary: true;
    chart: true;
  }
  interface Palette {
    secondary: PaletteRange;
    chart: PaletteRange;
  }
}

const joyTheme = extendTheme({
  colorSchemes: {
    light: {
      palette: {
        chart: {
          100: "#B3FFFA",
          200: "#00FFEE",
          300: "#00EBDB",
          400: "#00D6C8",
          50: "#DBFFFD",
          500: "#00BFB3",
          600: "#00ADA2",
          700: "#00998F",
          800: "#008077",
          900: "#005C56",
        },
        primary: {
          100: "#B3FFFA",
          200: "#00FFEE",
          300: "#00EBDB",
          400: "#00D6C8",
          50: "#DBFFFD",
          500: "#00BFB3",
          600: "#00ADA2",
          700: "#00998F",
          800: "#008077",
          900: "#005C56",
          solidColor: "black",
        },
        secondary: {
          100: "#EBE5FB",
          200: "#D8CAF7",
          300: "#BDA7F1",
          400: "#9C7BEA",
          50: "#F5F2FD",
          500: "#6732DE",
          600: "#5722D3",
          700: "#4C1EB9",
          800: "#3F199A",
          900: "#2C1169",
          outlinedActiveBg: "var(--joy-palette-secondary-100)",
          outlinedBorder: "var(--joy-palette-secondary-500)",
          outlinedColor: "var(--joy-palette-secondary-700)",
          plainActiveBg: "var(--joy-palette-secondary-100)",
          plainColor: "var(--joy-palette-secondary-700)",
          softActiveBg: "var(--joy-palette-secondary-300)",
          softBg: "var(--joy-palette-secondary-200)",
          softColor: "var(--joy-palette-secondary-800)",
          solidActiveBg: "var(--joy-palette-secondary-500)",
          solidBg: "var(--joy-palette-secondary-400)",
        },
        success: {
          100: "#B3FFFA",
          200: "#00FFEE",
          300: "#00EBDB",
          400: "#00D6C8",
          50: "#DBFFFD",
          500: "#00BFB3",
          600: "#00ADA2",
          700: "#00998F",
          800: "#008077",
          900: "#005C56",
          solidColor: "black",
        },
      },
    },
  },
  components: {
    JoyAccordionSummary: {
      defaultProps: {
        indicator,
      },
      styleOverrides: {
        indicator: {
          [`&.${selectClasses.expanded}`]: {
            ...indicatorActiveStyles,
          },
          transition: "0.2s",
        },
      },
    },
    JoyButton: {
      defaultProps: {
        color: "neutral",
        variant: "plain",
      },
    },
    JoyCircularProgress: {
      defaultProps: {
        thickness: 1,
      },
    },
    JoyDialogActions: {
      defaultProps: {
        orientation: "horizontal",
      },
      styleOverrides: {
        root: {
          [`& > ${buttonClasses.root}:only-child`]: { flex: "0 0 auto" },
          justifyContent: "space-between",
        },
      },
    },
    JoyFormHelperText: {
      styleOverrides: {
        root: {
          "&:empty": {
            display: "none",
          },
        },
      },
    },
    JoyMenuButton: {
      defaultProps: {
        endDecorator: <FontAwesomeIcon icon={faAngleDown} />,
      },
      styleOverrides: {
        // @ts-expect-error // NOTE: The prop exists, but the type definition is missing
        endDecorator: {
          color: "var(--Icon-color)",
          fontSize: "1.14285714286em",
          marginInlineEnd: "calc(var(--Button-paddingInline, 1rem) / -4)",
          transition: "0.2s",
        },
        root: {
          [`&[aria-expanded="true"] > .${menuButtonClasses.endDecorator}`]: {
            ...indicatorActiveStyles,
          },
        },
        startDecorator: {
          color: "var(--Icon-color)", // NOTE: Should probably be applied to `FontAwesomeIcon`'s `svg` element the same way as it is applied to the `SvgIcon` component
          fontSize: "var(--Icon-fontSize)", // NOTE: Should probably be applied to `FontAwesomeIcon`'s `svg` element the same way as it is applied to the `SvgIcon` component
        },
      },
    },
    JoyModalDialog: {
      defaultProps: {
        minWidth: 400,
        variant: "plain",
      },
      styleOverrides: {
        root: ({ theme }) => ({
          /* 
            NOTE: ModalDialog considers itself a Card, thus applies Card padding.
            That CSS variable can be used to adjust the paddings of the title, content, and actions.
            The content is expanded to fill the entire width using the same technique as `CardOverflow` does.
            This allows for better scrollbar positioning and avoids cutting off overflowing shadows.
          */
          [`& > .${dialogActionsClasses.root}`]: {
            paddingTop: "var(--Card-padding)",
          },
          [`& > .${dialogContentClasses.root}`]: {
            marginInline: "calc(var(--Card-padding) * -1)",
            padding: "var(--Card-padding)",
          },
          [`& > .${dialogTitleClasses.root}`]: {
            paddingBottom: "var(--Card-padding)",
          },
          [`& > .${dialogTitleClasses.root} + ${dialogContentClasses.root}`]: {
            paddingTop: 0,
          },
          "&[role=alertdialog]": {
            [`& > .${dialogContentClasses.root}`]: {
              textAlign: "center",
              textWrap: "balance",
            },
            [`& > .${dialogTitleClasses.root}`]: {
              "& >:not(svg)": {
                color: theme.palette.text.primary, // NOTE: The same effect can be achieved by using the `style={{ color: "initial" }}` prop on the `span` tag
              },
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              textWrap: "balance",
            },
            "--ModalDialog-maxWidth": "400px",
            "--ModalDialog-minWidth": "300px",
          },
          "--Card-padding": "1rem",
          gap: 0,
        }),
      },
    },
    JoySelect: {
      defaultProps: {
        indicator,
        slotProps: {
          button: ({ value }) => ({
            ...((value === null ||
              value === undefined ||
              value === "" ||
              (Array.isArray(value) && value.length === 0)) && {
              sx: {
                opacity: "var(--Select-placeholderOpacity)", // NOTE: Fixing opacity for multi-selects
              },
            }),
          }),
          listbox: {
            placement: "bottom-start",
          },
        },
      },
      styleOverrides: {
        indicator: {
          [`&.${selectClasses.expanded}`]: {
            ...indicatorActiveStyles,
          },
          transition: "0.2s",
        },
        root: ({ theme, ownerState }) => ({
          '&:active, &[aria-pressed="true"]':
            theme.variants[`${ownerState.variant!}Active`]?.[ownerState.color!],
        }),
      },
    },
    JoyStack: {
      defaultProps: {
        spacing: 1,
        useFlexGap: true,
      },
    },
    JoyTab: {
      defaultProps: {
        color: "neutral",
        indicatorInset: true,
      },
    },
    JoyTabList: {
      styleOverrides: {
        root: {
          [`& .${tabClasses.root}`]: {
            backgroundColor: "transparent",
            [`&.${tabClasses.selected}`]: {
              backgroundColor: "transparent",
            },
          },
        },
      },
    },
  },
});

const StylesWrapper: React.FC<StylesWrapperProps> = memo(({ children }) => {
  const styles = {
    ".MuiButton-startDecorator .svg-inline--fa": {
      color: "var(--Icon-color)",
      fontSize: "var(--Icon-fontSize)",
      margin: "var(--Icon-margin)",
    },
    ".svg-inline--fa.fa-fw": { width: "1em" },
    ":focus": { outline: "none" },
  };
  const theme = createDefaultTheme();
  return (
    <StyledEngineProvider injectFirst={true}>
      <GlobalStyles styles={styles} />
      <ThemeProvider theme={theme}>
        <MaterialCssVarsProvider
          theme={{ [MATERIAL_THEME_ID]: materialExtendTheme(theme) }}
        >
          <JoyCssVarsProvider theme={joyTheme}>
            <CssBaseline />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              {children}
            </LocalizationProvider>
          </JoyCssVarsProvider>
        </MaterialCssVarsProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
});
StylesWrapper.displayName = "StylesWrapper";

export default StylesWrapper;
