import {
  useCreateOrganizationUserMutation,
  useOrganizationDomainsLazyQuery,
  useOrganizationNameQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  UserFragment,
  UserRole,
  UsersDocument,
  type UsersQuery,
} from "@decentriq/graphql/dist/types";
import { zodResolver } from "@hookform/resolvers/zod";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Option,
  Select,
  Stack,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { useCallback, useEffect, useMemo } from "react";
import { Controller, useForm, useFormState } from "react-hook-form";
import { z } from "zod";
import { AdminDialog } from "components";
import {
  CommonSnackbarOrigin,
  useGeneralSnackbar,
  useIsDecentriqOrganization,
  useUserRole,
} from "hooks";
import { userRolePresentation } from "models";

interface AddOrganizationUserDialogProps {
  organizationId: string;
  open: boolean;
  onCancel: () => void;
}

const AddOrganizationUserDialog: React.FC<AddOrganizationUserDialogProps> = ({
  organizationId,
  open,
  onCancel,
}) => {
  const [fetchOrganizationDomains] = useOrganizationDomainsLazyQuery({
    variables: { organizationId },
  });
  const { data: organizationData } = useOrganizationNameQuery({
    fetchPolicy: "cache-only",
    skip: false,
  });

  const addOrganizationUserValidationSchema = useMemo(
    () =>
      z
        .object({
          email: z.string().min(1).email(),
          role: z.nativeEnum(UserRole),
        })
        .refine(
          async ({ email }) => {
            // eslint-disable-next-line no-console
            const organizationDomainsData = await fetchOrganizationDomains();
            const organizationDomains =
              organizationDomainsData?.data?.organization?.domains || [];
            const [, domain] = (email as string).split("@");
            return organizationDomains.includes(domain);
          },
          {
            message:
              "Only user emails matching the allowed domain(s) can be added to this organization.",
            path: ["email"],
          }
        ),
    [fetchOrganizationDomains]
  );

  const { handleSubmit, control, reset, watch, setValue, ...restForm } =
    useForm({
      defaultValues: {
        email: "",
        role: UserRole.NormalUser,
      },
      mode: "onChange",
      reValidateMode: "onChange",
      resolver: zodResolver(addOrganizationUserValidationSchema),
    });
  const { isValid } = useFormState({ control });

  const isDecentriqOrganization = useIsDecentriqOrganization(organizationId);
  const { isSuperAdmin } = useUserRole();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });

  const [createOrganizationUser, { loading }] =
    useCreateOrganizationUserMutation({
      onCompleted: () => {
        enqueueSnackbar(`User has been successfully created.`);
        onCancel();
      },
      onError: (error) =>
        enqueueSnackbar(`User could not be created.`, {
          context: error?.message,
          persist: true,
          variant: "error",
        }),
      update: (cache, { data }) => {
        // Add user to the organization
        cache.modify({
          fields: {
            users: (existing = {}) => {
              const userRef = cache.writeFragment({
                data: data?.organization?.createInternalUser?.record,
                fragment: UserFragment,
              });
              return {
                ...existing,
                nodes: [userRef, ...(existing?.nodes || [])],
                totalCount: Math.max((existing?.totalCount || 0) + 1, 0),
              };
            },
          },
          id: cache.identify({
            __typename: "Organization",
            id: organizationId,
          }),
        });

        // List of organizations and their users must be updated only for Decentriq Super Admin
        // as other roles have no permissions to access those lists
        if (!isSuperAdmin) return;

        // Add user to Users list
        const usersList = cache.readQuery<UsersQuery>({
          query: UsersDocument,
        });
        if (!usersList) return;

        cache.writeQuery({
          data: {
            users: {
              nodes: [
                {
                  ...data?.organization?.createInternalUser?.record,
                  organization: {
                    __typename: "Organization",
                    id: organizationId,
                    name: organizationData?.organization?.name,
                  },
                },
                ...(usersList?.users?.nodes || []),
              ],
            },
          },
          query: UsersDocument,
        });
      },
    });

  const handleCreateOrganizationUser = useCallback(() => {
    const { email, role } = restForm.getValues();
    createOrganizationUser({
      variables: {
        input: {
          email,
          organizationId,
          userRole: role,
        },
      },
    });
  }, [createOrganizationUser, organizationId, restForm]);

  useEffect(() => {
    // Clean fields whenever modal is closed
    reset();
  }, [open, reset]);

  const rolesOptions = [
    UserRole.NormalUser,
    UserRole.OrganizationAdmin,
    ...(isDecentriqOrganization && isSuperAdmin
      ? [UserRole.SuperAdmin, UserRole.SuperAdminReadOnly]
      : []),
  ];

  return (
    <AdminDialog
      disabled={!isValid}
      loading={loading}
      onClose={onCancel}
      onConfirm={handleSubmit(handleCreateOrganizationUser)}
      open={open}
      title="Create organization user"
    >
      <form>
        <Stack>
          <Controller
            control={control}
            name="email"
            render={({ field, formState }) => {
              const { errors } = formState;
              return (
                <FormControl error={!isEmpty(errors["email"])}>
                  <FormLabel>Email</FormLabel>
                  <Input placeholder="Email" {...field} />
                  <FormHelperText>{errors["email"]?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="role"
            render={({ field }) => {
              return (
                <FormControl>
                  <FormLabel>Role</FormLabel>
                  <Select
                    {...field}
                    onChange={(event, value) => field.onChange(value)}
                  >
                    {(rolesOptions as UserRole[]).map((role) => (
                      <Option key={role} value={role}>
                        {userRolePresentation.get(role)}
                      </Option>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </Stack>
      </form>
    </AdminDialog>
  );
};

export default AddOrganizationUserDialog;
