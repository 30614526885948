// TODO: Fix joy migration
import { faGear, faInfoCircle, faKey } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { ExternalConnectionActionsWrapper } from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "../../../../types";

const snowflakeFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    databaseName: yup.string().trim().required("Database name is required"),
    schemaName: yup.string().trim().required("Schema name is required"),
    stageName: yup.string().trim().required("Stage name is required"),
    tableName: yup.string().trim().required("Table name is required"),
    warehouseName: yup.string().trim().required("Warehouse name is required"),
  }),
  credentials: yup.object({
    accountId: yup.string().trim().required("Account ID is required"),
    password: yup.string().trim().required("Password is required"),
    role: yup.string().trim().required("Role is required"),
    username: yup.string().trim().required("Username is required"),
  }),
  datasetName: yup.string(),
});

type SnowflakeFormValues = yup.InferType<typeof snowflakeFormValidationSchema>;

const SnowflakeForm: React.FC<
  ImportExternalDataFormProps & {
    ActionsWrapper?: React.ComponentType;
    FormWrapper?: React.ComponentType;
  }
> = ({
  onCancel,
  onSubmit,
  ActionsWrapper = ExternalConnectionActionsWrapper,
  FormWrapper = Box,
}) => {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      configuration: {
        databaseName: "",
        schemaName: "",
        stageName: "",
        tableName: "",
        warehouseName: "",
      },
      credentials: {
        accountId: "",
        password: "",
        role: "",
        username: "",
      },
      datasetName: "",
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(snowflakeFormValidationSchema),
  });
  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);
  const handleFormSubmit = useCallback(
    (formValues: SnowflakeFormValues) => {
      const {
        configuration = {},
        credentials = {},
        datasetName = "",
      } = formValues;
      onSubmit({
        input: {
          datasetName: datasetName.trim() || configuration?.tableName?.trim(),
          snowflake: {
            credentials,
            sourceConfig: configuration,
          },
        },
      });
      reset();
    },
    [reset, onSubmit]
  );
  // eslint-disable-next-line no-console
  console.log({ errors });
  return (
    <>
      <FormWrapper>
        <form>
          <Stack>
            <Typography
              level="title-md"
              sx={{ alignItems: "center", display: "flex", gap: 1 }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faGear} />
              Configuration
            </Typography>

            <Controller
              control={control}
              name="configuration.warehouseName"
              render={({ field, fieldState }) => {
                const { error } = fieldState;
                const hasError = !isEmpty(error);
                return (
                  <FormControl error={hasError} required={true}>
                    <FormLabel>Warehouse name</FormLabel>
                    <Input placeholder={`e.g. "DEV_WAREHOUSE"`} {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.databaseName"
              render={({ field, fieldState }) => {
                const { error } = fieldState;
                const hasError = !isEmpty(error);
                return (
                  <FormControl error={hasError} required={true}>
                    <FormLabel>Database name</FormLabel>
                    <Input placeholder={`e.g. "TEST_DATABASE"`} {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.schemaName"
              render={({ field, fieldState }) => {
                const { error } = fieldState;
                const hasError = !isEmpty(error);
                return (
                  <FormControl error={hasError} required={true}>
                    <FormLabel>Schema name</FormLabel>
                    <Input placeholder={`e.g. "TEST_SCHEMA"`} {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.tableName"
              render={({ field, fieldState }) => {
                const { error } = fieldState;
                const hasError = !isEmpty(error);
                return (
                  <FormControl error={hasError} required={true}>
                    <FormLabel>Table name</FormLabel>
                    <Input placeholder={`e.g. "DATA_TABLE"`} {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="configuration.stageName"
              render={({ field, fieldState }) => {
                const { error } = fieldState;
                const hasError = !isEmpty(error);
                return (
                  <FormControl error={hasError} required={true}>
                    <FormLabel>Stage name</FormLabel>
                    <Input placeholder={`e.g. "my_int_stage"`} {...field} />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="datasetName"
              render={({ field, fieldState }) => {
                const { error } = fieldState;
                const hasError = !isEmpty(error);
                return (
                  <FormControl error={hasError}>
                    <FormLabel>Stored dataset name</FormLabel>
                    <Input
                      placeholder={`e.g. "decentriq_dataset_name"`}
                      {...field}
                    />
                    <FormHelperText>{error?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <FormHelperText></FormHelperText>
            <Typography
              level="title-md"
              sx={{ alignItems: "center", display: "flex", gap: 1 }}
            >
              <FontAwesomeIcon fixedWidth={true} icon={faKey} />
              <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
                Credentials
                <Tooltip
                  placement="top"
                  title="Credentials are encrypted and utilized only once by the enclave."
                >
                  <small>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </small>
                </Tooltip>
              </Box>
            </Typography>
            <Controller
              control={control}
              name="credentials.accountId"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.accountId;
                return (
                  <FormControl error={!isEmpty(fieldError)} required={true}>
                    <FormLabel>Account ID</FormLabel>
                    <Input placeholder={`e.g. "sdkreui-mt37045"`} {...field} />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="credentials.role"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.role;
                return (
                  <FormControl error={!isEmpty(fieldError)} required={true}>
                    <FormLabel>Role</FormLabel>
                    <Input placeholder={`e.g. "PUBLIC"`} {...field} />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="credentials.username"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.username;
                return (
                  <FormControl error={!isEmpty(fieldError)} required={true}>
                    <FormLabel>Username</FormLabel>
                    <Input placeholder={`e.g. "testuser"`} {...field} />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            <Controller
              control={control}
              name="credentials.password"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.credentials?.password;
                return (
                  <FormControl error={!isEmpty(fieldError)} required={true}>
                    <FormLabel>Password</FormLabel>
                    <Input
                      placeholder={`e.g. "Cfw3{?F^K46m(fj"`}
                      type="password"
                      {...field}
                    />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
          </Stack>
        </form>
      </FormWrapper>
      <ActionsWrapper>
        <Button onClick={handlePreviousStepClick}>Back</Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="solid"
        >
          Import
        </Button>
      </ActionsWrapper>
    </>
  );
};

SnowflakeForm.displayName = "SnowflakeForm";

export default memo(SnowflakeForm);
