import { z } from "zod";

export const schema = z.object({
  configuration: z.object({
    blobName: z
      .string({ required_error: "Blob name is required" })
      .trim()
      .min(1, "Blob name is required"),
    storageAccount: z
      .string({ required_error: "Storage account is required" })
      .trim()
      .min(1, "Storage account is required"),
    storageContainer: z
      .string({ required_error: "Storage container is required" })
      .trim()
      .min(1, "Storage container is required"),
  }),
  credentials: z.object({
    sasToken: z
      .string({ required_error: "SAS token is required" })
      .trim()
      .min(1, "SAS token is required"),
  }),
  datasetName: z.string().optional(),
});

export type SchemaType = z.infer<typeof schema>;

export const defaultValues = {
  configuration: {
    blobName: "",
    storageAccount: "",
    storageContainer: "",
  },
  credentials: {
    sasToken: "",
  },
  datasetName: undefined,
};
