import { Typography } from "@mui/material";
import { memo } from "react";

const AudienceGeneratorStepSectionTitle = memo<React.PropsWithChildren>(
  ({ children }) => (
    <Typography fontWeight="fontWeightSemiBold" variant="subtitle1">
      {children}
    </Typography>
  )
);

AudienceGeneratorStepSectionTitle.displayName =
  "AudienceGeneratorStepSectionTitle";

export default AudienceGeneratorStepSectionTitle;
