import { Box, Tooltip } from "@mui/joy";
import { AppBar, type Theme, Toolbar } from "@mui/material";
import { memo } from "react";
import { makeStyles } from "tss-react/mui";
import { Label } from "components";
import { MediaDataRoomActions } from "features/mediaDataRoom";
import { usePublishedMediaInsightsDcr } from "features/mediaDataRoom/contexts";

export const useToolbarStyles = makeStyles()((theme: Theme) => ({
  menuLeftWrapper: {
    alignItems: "center",
    display: "flex",
    flex: 1,
    flexDirection: "row",
    marginRight: theme.spacing(0.5),
    maxWidth: "100%",
    overflow: "hidden",
  },
  nameEditorWrapper: {
    alignItems: "center",
    display: "flex",
    fontSize: "20px",
    fontWeight: 600,
    lineHeight: "24px",
    maxWidth: "100%",
    padding: theme.spacing(0.5),
  },
  toolbar: {
    display: "grid",
    gridAutoFlow: "column",
    gridTemplateColumns: "minmax(0, 1fr) auto",
    justifyContent: "space-between",
    margin: theme.spacing(1.25, 2),
    minHeight: "32px !important",
  },
}));

interface MediaDataRoomMainbarProps {
  id: string;
}

const MediaDataRoomMainbar: React.FC<MediaDataRoomMainbarProps> = memo(
  ({ id }) => {
    const { classes: toolbarClasses } = useToolbarStyles();
    const { toolbar, menuLeftWrapper, nameEditorWrapper } = toolbarClasses;
    const { definition, isDeactivated, isStopped } =
      usePublishedMediaInsightsDcr();
    const { name } = definition ?? {};
    return (
      <AppBar
        color="transparent"
        elevation={0}
        position="static"
        sx={{ backgroundColor: "background.bar" }}
      >
        <Box>
          <Toolbar className={toolbar} disableGutters={true}>
            <div className={menuLeftWrapper}>
              <div className={nameEditorWrapper}>
                {name}
                <Box sx={{ ml: 0.5, mt: "2px" }}>
                  {isStopped ? (
                    <Tooltip title="This data clean room is in STOPPED state.">
                      <Label label="STOPPED" />
                    </Tooltip>
                  ) : isDeactivated ? (
                    <Tooltip title="This data clean room is in DEACTIVATED state.">
                      <Label label="DEACTIVATED" />
                    </Tooltip>
                  ) : null}
                </Box>
              </div>
            </div>
            <Box>
              <Box sx={{ display: "flex" }}>
                <MediaDataRoomActions
                  id={id}
                  inline={false}
                  withPerformanceReport={true}
                />
              </Box>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
    );
  }
);

export default MediaDataRoomMainbar;
