import { DataTargetType } from "@decentriq/graphql/dist/types";
import { Box, DialogTitle, Divider, Drawer, Sheet, Typography } from "@mui/joy";
import { memo, useCallback, useEffect } from "react";
import {
  dataTargetTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import {
  EXPORT_DATASET_STEPS,
  useExportDatasetDialog,
} from "features/datasets/components/ExportDataset";
import {
  ExportDatasetStatus,
  ExportDatasetTargetForm,
  ExportDatasetTargetSelector,
} from "./components";

type ExportDatasetDialogProps = {
  open: boolean;
  onCancel?: () => void;
};

const ExportDatasetDialog: React.FC<ExportDatasetDialogProps> = ({
  open,
  onCancel,
}) => {
  const {
    exportStep,
    setExportStep,
    setDatasetExportId,
    setSelectedDataTarget,
    setIsExportCreated,
    selectedDataTarget,
  } = useExportDatasetDialog();
  useEffect(() => {
    if (!open) {
      setSelectedDataTarget(DataTargetType.S3);
      setExportStep(EXPORT_DATASET_STEPS.SELECT_TARGET);
      setDatasetExportId(null);
      setIsExportCreated(false);
    }
  }, [
    open,
    setSelectedDataTarget,
    setExportStep,
    setIsExportCreated,
    setDatasetExportId,
  ]);
  const handleClose = useCallback(() => onCancel?.(), [onCancel]);
  return (
    // TODO: Fix joy migrationю кугіу вйішвузфтуд
    <Drawer
      anchor="right"
      open={open}
      slotProps={{
        content: {
          sx: {
            bgcolor: "transparent",
            boxShadow: "none",
            p: { md: 3, sm: 0 },
          },
        },
      }}
    >
      <Sheet
        sx={{
          "--Card-padding": "1rem",
          borderRadius: "md",
          display: "flex",
          flexDirection: "column",
          height: "100%",
          overflow: "auto",
        }}
      >
        <DialogTitle>Export dataset</DialogTitle>
        <Divider />
        {exportStep === EXPORT_DATASET_STEPS.SELECT_TARGET && (
          <ExportDatasetTargetSelector onClose={handleClose} />
        )}
        {exportStep === EXPORT_DATASET_STEPS.TARGET_FORM && (
          <>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                marginBottom: "16px",
              }}
            >
              <ExternalConnectionsIcon
                connectionType={selectedDataTarget}
                size={ExternalConnectionsIconSize.sm}
              />
              <Typography sx={{ marginLeft: "4px" }}>
                {dataTargetTypePresentation.get(selectedDataTarget)}
              </Typography>
            </Box>
            <ExportDatasetTargetForm />
          </>
        )}
        {exportStep === EXPORT_DATASET_STEPS.EXPORT_STATUS && (
          <ExportDatasetStatus onClose={handleClose} />
        )}
      </Sheet>
    </Drawer>
  );
};

ExportDatasetDialog.displayName = "ExportDatasetDialog";

export default memo(ExportDatasetDialog);
