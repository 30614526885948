// TODO: Fix joy migration
import { Box, Divider, Grid } from "@mui/joy";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  DownloadMediaDataRoomInsightsButton,
  MediaDataRoomInsightsCharts,
  MediaDataRoomInsightsExplorerTable,
  MediaDataRoomInsightsForm,
  type MediaDataRoomInsightsFormValues,
  OverlapInsightsVisualizeDirection,
  useMediaDataRoomOverlapInsightsContext,
} from "features/mediaDataRoom";

const MediaDataRoomInsightsExplorer: React.FC = () => {
  const { audienceType } = useMediaDataRoomOverlapInsightsContext();
  const form = useForm<MediaDataRoomInsightsFormValues>({
    defaultValues: {
      aggregation: "",
      audienceType: audienceType || "",
      chartType: "RADAR",
      visualize: {
        direction: OverlapInsightsVisualizeDirection.top,
        limit: 20,
        orderBy: "",
      },
    },
    mode: "onChange",
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        overflow: "auto",
        width: "100%",
      }}
    >
      <FormProvider {...form}>
        <Grid container={true} mb={3}>
          <Grid container={true} md={3} xs={12}>
            <MediaDataRoomInsightsForm />
          </Grid>
          <Grid container={true} justifyContent="center" xs={1}>
            <Divider flexItem={true} orientation="vertical" />
          </Grid>
          <Grid
            container={true}
            flexDirection="column"
            justifyContent="center"
            maxHeight={500}
            md={8}
            minHeight={300}
            xs={12}
          >
            <Box
              sx={{
                height: "100%",
                maxHeight: "300px",
                position: "relative",
                width: "100%",
              }}
            >
              <MediaDataRoomInsightsCharts />
              <Box sx={{ position: "absolute", right: 0, top: 0 }}>
                <DownloadMediaDataRoomInsightsButton />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ flex: 1, height: 350 }}>
          <MediaDataRoomInsightsExplorerTable />
        </Box>
      </FormProvider>
    </div>
  );
};

MediaDataRoomInsightsExplorer.displayName = "MediaDataRoomInsightsExplorer";

export default memo(MediaDataRoomInsightsExplorer);
