import { useApolloClient } from "@apollo/client";
import { CreateDatasetImportDocument } from "@decentriq/graphql/dist/types";
import { useSafeState } from "ahooks";
import { type SnackbarKey } from "notistack";
import { useCallback, useState } from "react";
import {
  type ActivationType,
  type Audience,
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/mediaDataRoom";
import { getMediaInsightsAudienceCacheKey } from "features/mediaDataRoom/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { parseMediaDataRoomError } from "utils";

interface StoreAsDatasetHookPayload {
  activationType: ActivationType;
  activatedAudiences: Audience[];
  audienceType: string;
  reach: number;
}

export const useStoreAsDataset = ({
  activationType,
  activatedAudiences,
  audienceType,
  reach,
}: StoreAsDatasetHookPayload) => {
  const [loading, setLoading] = useSafeState(false);
  const { dataRoomId, driverAttestationHash, isPublisher } =
    usePublishedMediaInsightsDcr();
  const apolloClient = useApolloClient();
  const { advertiserDatasetHash, publisherDatasetsHashes } =
    useMediaInsightsDcrData();

  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];

  const handleSaveAsDataSet = useCallback(async () => {
    setErrorSnackbarId((snackbarId) => {
      if (snackbarId) {
        closeSnackbar(snackbarId);
      }
      return undefined;
    });
    setLoading(true);
    try {
      const cacheKey = await getMediaInsightsAudienceCacheKey({
        activatedAudiences,
        activationType,
        advertiserDatasetHash,
        audienceType,
        dataRoomId,
        publisherDatasetsHashes,
        reach,
      });

      if (cacheKey == null) {
        throw new Error(
          "Storing an audience as dataset requires both the publisher and advertiser dataset uploaded"
        );
      }
      // The filename format is used by the JS client when exporting to Splicky.
      // **DO NOT** change the format without ensuring the Splicky connector works
      // as expected.
      const audienceTypeDisplay =
        activationType === "retarget" ? "remarketing" : activationType;
      let filename = `${audienceTypeDisplay} ${audienceType}`;
      if (reach) {
        filename += ` - ${reach}%`;
      }
      await apolloClient.mutate({
        mutation: CreateDatasetImportDocument,
        variables: {
          input: {
            compute: {
              computeNodeId: isPublisher
                ? "get_audience_user_list"
                : "get_audience_user_list_for_advertiser",
              dataRoomId,
              driverAttestationHash,
              importFileWithName: "audience_users.csv",
              isHighLevelNode: false,
              parameters: [
                {
                  computeNodeName: "requested_audience.json",
                  content: JSON.stringify({
                    activation_type: activationType,
                    audience_type: audienceType,
                    // Need to specify reach as null since
                    // undefined props are stripped form the JSON
                    // and the Python script expects a `reach` property
                    reach: reach ?? null,
                  }),
                },
              ],
              renameFileTo: filename,
              shouldImportAllFiles: false,
              shouldImportAsRaw: false,
            },
            datasetName: filename,
          },
        },
      });
    } catch (error) {
      setErrorSnackbarId(
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            parseMediaDataRoomError(error),
            "Unable to save audience as dataset"
          )
        )
      );
      throw error;
    } finally {
      setLoading(false);
    }
  }, [
    activatedAudiences,
    activationType,
    advertiserDatasetHash,
    apolloClient,
    audienceType,
    closeSnackbar,
    dataRoomId,
    driverAttestationHash,
    enqueueSnackbar,
    publisherDatasetsHashes,
    reach,
    setErrorSnackbarId,
    setLoading,
    isPublisher,
  ]);

  return {
    loading,
    saveAsDataSet: handleSaveAsDataSet,
  };
};
