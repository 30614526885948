import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
  Tooltip,
} from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import ControlledInput from "../ControlledInput/ControlledInput";

interface GoogleCloudStorageFormControlsProps {
  type: ExternalConnectionType;
}

const GoogleCloudStorageFormControls: React.FC<GoogleCloudStorageFormControlsProps> =
  memo(({ type }) => {
    const { control, setError } = useFormContext();
    return (
      <Stack>
        <ExternalConnectionConfigurationLabel />
        <ControlledInput
          label="Bucket name"
          name="gcs.bucketName"
          placeholder={`e.g. "bucket-name"`}
        />
        <ControlledInput
          label="Object name"
          name="gcs.objectName"
          placeholder={`e.g. "object-name"`}
        />
        {type === ExternalConnectionType.IMPORT ? (
          <ControlledInput
            label="Stored dataset name"
            name="datasetName"
            placeholder={`e.g. "dataset-name"`}
            required={false}
          />
        ) : null}
        <ExternalConnectionCredentialsLabel />
        <Controller
          control={control}
          name="gcs.credentials"
          render={({ field: { value, onChange, ...field }, fieldState }) => {
            const { error } = fieldState;
            return (
              <FormControl
                disabled={field.disabled}
                error={Boolean(error)}
                required={true}
              >
                <FormLabel>
                  Service account key in JSON format
                  <Box ml={0.5}>
                    <Tooltip
                      placement="top"
                      sx={{ maxWidth: 300 }}
                      title={
                        <span>
                          Please{" "}
                          <a
                            href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                            rel="noreferrer"
                            style={{ color: "inherit" }}
                            target="_blank"
                          >
                            follow the instructions
                          </a>{" "}
                          to obtain a service account key
                        </span>
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <Input
                  {...field}
                  slotProps={{
                    input: {
                      accept: "application/JSON",
                      multiple: false,
                      onChange: (
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const file = (event.target.files || [])[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          try {
                            if (!reader.result) {
                              throw new Error("Credentials file is not valid");
                            }
                            const parsedJSON = JSON.parse(
                              reader.result as string
                            );
                            const stringifiedJSON = JSON.stringify(
                              parsedJSON,
                              null,
                              4
                            );
                            if (!stringifiedJSON) {
                              throw new Error("Credentials file is not valid");
                            }
                            onChange(stringifiedJSON);
                          } catch (error) {
                            setError("gcs.credentials", {
                              message: error?.message,
                            });
                          }
                        };
                        reader.readAsText(file);
                      },
                      type: "file",
                    },
                    root: { sx: { alignItems: "center" } },
                  }}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
      </Stack>
    );
  });
GoogleCloudStorageFormControls.displayName = "GoogleCloudStorageFormControls";

export default GoogleCloudStorageFormControls;
