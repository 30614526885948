import { faPlus, faTrashCan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  IconButton,
  Input,
  Stack,
} from "@mui/joy";
import { get } from "lodash";
import { memo } from "react";
import {
  Controller,
  type FieldError,
  type Merge,
  useFieldArray,
  useFormContext,
} from "react-hook-form";

const SegmentOwnersField: React.FC = () => {
  const { control } = useFormContext();
  const {
    fields: segmentOwnersFields,
    append: appendSegmentOwner,
    remove: removeSegmentOwner,
  } = useFieldArray({
    control,
    name: "segmentOwners",
  });

  return (
    <Stack>
      {segmentOwnersFields.length ? (
        <FormLabel>Segment Owners</FormLabel>
      ) : null}
      {segmentOwnersFields.map((arrayFieldId, index) => {
        return (
          <Controller
            control={control}
            key={arrayFieldId.id}
            name={`segmentOwners.${index}.id`}
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = get(errors?.segmentOwners, `${index}.id`) as
                | Merge<FieldError, (FieldError | undefined)[]>
                | undefined;
              return (
                <FormControl error={!!fieldError}>
                  <FormLabel>{`Segment Owner ${index + 1}`}</FormLabel>
                  <Stack direction="row" sx={{ flex: 1 }}>
                    <Input
                      placeholder={`Example: KLAB00${index + 1}`}
                      {...field}
                    />
                    {segmentOwnersFields.length > 1 ? (
                      <IconButton onClick={() => removeSegmentOwner(index)}>
                        <FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />
                      </IconButton>
                    ) : null}
                  </Stack>
                  <FormHelperText>{fieldError?.message || ""}</FormHelperText>
                </FormControl>
              );
            }}
          />
        );
      })}
      <Button
        onClick={() => appendSegmentOwner({ id: "" })}
        startDecorator={<FontAwesomeIcon icon={faPlus} />}
      >
        Add Segment Owner
      </Button>
    </Stack>
  );
};

SegmentOwnersField.displayName = "SegmentOwnersField";

export default memo(SegmentOwnersField);
