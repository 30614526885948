import { createContext, useContext } from "react";
import { type QueryAvailableAudiencesHookResult } from "features/MediaInsightsDcr/hooks/useQueryAvailableAudiences";

export interface RulesBasedAdvertiserAudienceGeneratorContextValue {
  availableAudiences: QueryAvailableAudiencesHookResult;
  config: any;
  audienceName: string;
  setAudienceName: (audienceName: string) => void;
  estimatedAudienceSize: number | null;
  generateLookalikeAudienceLoading: boolean;
  generateLookalikeAudience: () => void;
}

export const RulesBasedAdvertiserAudienceGeneratorContext =
  createContext<RulesBasedAdvertiserAudienceGeneratorContextValue>({
    audienceName: "",
    availableAudiences: {
      error: undefined,
      loading: false,
      retry: async () => {},
      status: "COMPUTING",
    },
    config: {},
    estimatedAudienceSize: null,
    generateLookalikeAudience: () => {},
    generateLookalikeAudienceLoading: false,
    setAudienceName: () => {},
  });

export const RulesBasedAdvertiserAudienceGeneratorProvider =
  RulesBasedAdvertiserAudienceGeneratorContext.Provider;

export const useRulesBasedAdvertiserAudienceGenerator = () =>
  useContext(RulesBasedAdvertiserAudienceGeneratorContext);
