import { useBoolean } from "ahooks";
import { memo, useMemo } from "react";
import { OverlapInsightsExecutionContext } from "features/mediaDataRoom/components";
import { MediaDataRoomOverlapInsightsProvider } from "features/mediaDataRoom/contexts";

type MediaDataRoomInsightsWrapperProps = {
  executionContext: OverlapInsightsExecutionContext;
  children: React.ReactNode;
  audienceType?: string;
  reach?: number;
};

const MediaDataRoomInsightsWrapper: React.FC<
  MediaDataRoomInsightsWrapperProps
> = ({
  executionContext = OverlapInsightsExecutionContext.advertiserInsights,
  audienceType = "",
  reach,
  children,
}) => {
  const [
    isInsightsDialogOpen,
    { setTrue: openInsightsDialog, setFalse: closeInsightsDialog },
  ] = useBoolean(false);
  const contextValue = useMemo(
    () => ({
      audienceType,
      closeInsightsDialog,
      executionContext,
      isInsightsDialogOpen,
      openInsightsDialog,
      reach,
    }),
    [
      audienceType,
      closeInsightsDialog,
      executionContext,
      isInsightsDialogOpen,
      openInsightsDialog,
      reach,
    ]
  );
  return (
    <MediaDataRoomOverlapInsightsProvider value={contextValue}>
      {children}
    </MediaDataRoomOverlapInsightsProvider>
  );
};

MediaDataRoomInsightsWrapper.displayName = "MediaDataRoomInsightsWrapper";

export default memo(MediaDataRoomInsightsWrapper);
