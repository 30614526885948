// TODO: Fix joy migration
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { isEmpty } from "lodash";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const S3BucketFormConfigurationFields: React.FC = () => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name="configuration.url"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.configuration?.url;
          return (
            <FormControl error={!isEmpty(fieldError)} required={true}>
              <FormLabel>S3 Bucket name</FormLabel>
              <Input placeholder={`e.g. "my-bucket-name"`} {...field} />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="configuration.region"
        render={({ field, formState }) => {
          const { errors } = formState;
          const fieldError = errors?.configuration?.region;
          return (
            <FormControl error={!isEmpty(fieldError)} required={true}>
              <FormLabel>Region code</FormLabel>
              <Input placeholder={`e.g. "eu-central-2"`} {...field} />
              <FormHelperText>{fieldError?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </>
  );
};
S3BucketFormConfigurationFields.displayName = "S3BucketFormConfigurationFields";

export default memo(S3BucketFormConfigurationFields);
