import { SANITIZE_IDENTIFIER_INPUT } from "constants/index";
import { InlineEditor } from "@decentriq/components";
import { testIds } from "@decentriq/utils";
import {
  faCaretDown,
  faCaretRight,
  faClone,
  faColumns3,
  faRepeat,
  faTrashCan,
  faTriangleExclamation,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/joy";
import { orange } from "@mui/material/colors";
import { Fragment, useCallback } from "react";
import { DataRoomParticipantAvatarGroup } from "components";
import { useDataNodeActions } from "features/dataNodes/containers/DataNodes/DataNodesActionsWrapper";
import { type DataRoomData, type DataRoomDataTable } from "models";
import { isValidIdentifier, sanitizeIdentifier } from "utils/validation";
import { useDataNodeConstructorParams } from "./DataNodeConstructorParamsWrapper";

interface DataNodeEditableTileProps {
  dataNode: DataRoomData;
  isCollapsed: boolean;
  isLoading: boolean;
  hasError: boolean;
  otherDataNodeNames: string[];
  onToggleCollapsion: (dataNodeId: string) => void;
  onChangeOutcome?: () => void;
  renderDataActions?: (dataNode: DataRoomData) => React.ReactNode;
  dataTestid?: string;
  helperTestid?: string;
}

const getDuplicatedNodeName = (
  nodeName: string,
  nodeNames: string[]
): string => {
  const nextName = `Copy of ${nodeName}`;
  if (!nodeNames.includes(nextName)) {
    return nextName;
  }
  return getDuplicatedNodeName(nextName, nodeNames);
};

const DataNodeEditableTile: React.FC<DataNodeEditableTileProps> = ({
  dataNode,
  isCollapsed,
  isLoading,
  hasError,
  otherDataNodeNames,
  onChangeOutcome,
  onToggleCollapsion,
  renderDataActions,
  dataTestid,
  helperTestid,
}) => {
  const { withUploading, withDataDeletion, withDatasetStatus } =
    useDataNodeConstructorParams();
  const {
    handleDelete: deleteNode,
    handleNameUpdate,
    handleDuplicate,
  } = useDataNodeActions();
  const { name, id, dataType } = dataNode;
  const toggle = useCallback(
    () => onToggleCollapsion(id),
    [onToggleCollapsion, id]
  );
  const handleDelete = useCallback(
    async (node: DataRoomData) => deleteNode(node).then(onChangeOutcome),
    [deleteNode, onChangeOutcome]
  );
  return (
    <Fragment>
      <Grid container={true} spacing={1} style={{ minHeight: 40 }}>
        <Grid
          alignItems="center"
          container={true}
          style={{
            alignItems: "flex-start",
            flexWrap: "nowrap",
          }}
          xs={12}
        >
          <IconButton
            data-testid={testIds.dataNode.dataNodeCreator.toggleEditor}
            onClick={toggle}
          >
            <FontAwesomeIcon
              fixedWidth={true}
              icon={isCollapsed ? faCaretRight : faCaretDown}
            />
          </IconButton>
          <div style={{ flex: 1, margin: 4, width: 0 }}>
            <InlineEditor
              autoFocus={true}
              cancelEditingButtonEnabled={false}
              dataTestid={dataTestid}
              disableRestoreFocus={true}
              helperTestid={helperTestid}
              onChange={(value: string) => {
                handleNameUpdate({
                  dataType,
                  id,
                  name: SANITIZE_IDENTIFIER_INPUT
                    ? sanitizeIdentifier(value)
                    : value,
                });
              }}
              placeholder="Name"
              readOnly={false}
              saveEditingButtonEnabled={false}
              saveEditingOnClickAway={true}
              validate={(value: string) => {
                return !isValidIdentifier(value)
                  ? "Identifiers should begin with a letter, not end in an underscore, and should contain only alphanumeric characters or spaces"
                  : otherDataNodeNames.includes(value)
                    ? "Table name must be unique"
                    : value.length > 100
                      ? "Table name must be less than 100 characters"
                      : !value
                        ? "Table name must be set"
                        : "";
              }}
              value={name}
            />
          </div>
          {dataNode.dataType === "table" && (
            <Typography style={{ lineHeight: "32px", marginLeft: 8 }}>
              {(dataNode as DataRoomDataTable).columns.length === 0 ? (
                <FontAwesomeIcon
                  color={orange[400]}
                  fixedWidth={true}
                  icon={faTriangleExclamation}
                  style={{ marginRight: 4 }}
                />
              ) : (
                <FontAwesomeIcon icon={faColumns3} />
              )}
              {(dataNode as DataRoomDataTable).columns.length} column
              {(dataNode as DataRoomDataTable).columns.length !== 1 ? "s" : ""}
            </Typography>
          )}
          <Box style={{ display: "flex", marginLeft: 8 }}>
            <DataRoomParticipantAvatarGroup
              participants={dataNode.participants.map(
                ({ userEmail: name, uploadedAt }) => ({
                  active: !!uploadedAt && withDatasetStatus,
                  name,
                })
              )}
            />
          </Box>
          <Box
            sx={{
              alignItems: "center",
              cursor: "default",
              display: "flex",
              gap: 1,
              height: "100%",
              marginLeft: "auto",
            }}
          >
            {hasError && !isLoading && (
              <IconButton type="submit">
                <FontAwesomeIcon fixedWidth={true} icon={faRepeat} />
              </IconButton>
            )}
            {isLoading ? (
              <CircularProgress
                sx={{ "--CircularProgress-size": "16px", marginRight: "8px" }}
              />
            ) : (
              <Box>
                <Tooltip id="button-duplicate" title="Duplicate">
                  <IconButton
                    disabled={isLoading}
                    onClick={() =>
                      handleDuplicate({
                        ...dataNode,
                        name: getDuplicatedNodeName(
                          dataNode.name,
                          otherDataNodeNames
                        ),
                      })
                    }
                  >
                    <FontAwesomeIcon fixedWidth={true} icon={faClone} />
                  </IconButton>
                </Tooltip>
                <Tooltip id="button-delete" title="Delete">
                  <IconButton
                    disabled={isLoading}
                    onClick={() => handleDelete(dataNode)}
                  >
                    <FontAwesomeIcon fixedWidth={true} icon={faTrashCan} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {(withUploading || withDataDeletion) && renderDataActions
              ? renderDataActions(dataNode)
              : null}
          </Box>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DataNodeEditableTile;
