import {
  createContext,
  memo,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "../../../contexts";
import { type QueryAvailableAudiencesHookResult } from "../../../hooks/useQueryAvailableAudiences";

export enum AdvertiserAudienceGeneratorType {
  LOOKALIKE = "LOOKALIKE",
  RULES_BASED = "RULES_BASED",
}

export enum AdvertiserAudienceGeneratorStep {
  SELECT_TYPE = "SELECT_TYPE",
  CONFIGURATION = "CONFIGURATION",
  SUMMARY = "SUMMARY",
}

const defaultAdvertiserAudienceGeneratorSteps = [
  AdvertiserAudienceGeneratorStep.SELECT_TYPE,
  AdvertiserAudienceGeneratorStep.CONFIGURATION,
  AdvertiserAudienceGeneratorStep.SUMMARY,
];

interface AdvertiserAudienceGeneratorContextValue {
  generatorType: AdvertiserAudienceGeneratorType | null;
  selectGeneratorType: (type: AdvertiserAudienceGeneratorType) => void;
  generationSteps: AdvertiserAudienceGeneratorStep[];
  currentStep: AdvertiserAudienceGeneratorStep;
  availableAudiences: QueryAvailableAudiencesHookResult;
  hasMultipleGenerators: boolean;
  cancelGeneration: () => void;
  handleNextStep: () => void;
  handleBackStep: () => void;
  isFirstStep: (step: AdvertiserAudienceGeneratorStep) => boolean;
}

export const AdvertiserAudienceGeneratorContext =
  createContext<AdvertiserAudienceGeneratorContextValue>({
    availableAudiences: {
      error: undefined,
      loading: false,
      retry: async () => {},
      status: "COMPUTING",
    },
    cancelGeneration: () => {},
    currentStep: AdvertiserAudienceGeneratorStep.SELECT_TYPE,
    generationSteps: defaultAdvertiserAudienceGeneratorSteps,
    generatorType: null,
    handleBackStep: () => {},
    handleNextStep: () => {},
    hasMultipleGenerators: false,
    isFirstStep: () => false,
    selectGeneratorType: () => {},
  });

export const AdvertiserAudienceGeneratorProvider =
  AdvertiserAudienceGeneratorContext.Provider;

export const useAdvertiserAudienceGenerator = () =>
  useContext(AdvertiserAudienceGeneratorContext);

export const AdvertiserAudienceGeneratorWrapper = memo<React.PropsWithChildren>(
  ({ children }) => {
    const { dataRoomId, features } = usePublishedMediaInsightsDcr();
    // TODO: add rule-based feature check
    const hasMultipleGenerators = useMemo(
      () => features.lookalike && false,
      [features.lookalike]
    );
    const [currentStep, setCurrentStep] =
      useState<AdvertiserAudienceGeneratorStep>(
        hasMultipleGenerators
          ? AdvertiserAudienceGeneratorStep.SELECT_TYPE
          : AdvertiserAudienceGeneratorStep.CONFIGURATION
      );
    const [generatorType, setGeneratorType] =
      useState<AdvertiserAudienceGeneratorType | null>(
        hasMultipleGenerators
          ? null
          : features.lookalike
            ? AdvertiserAudienceGeneratorType.LOOKALIKE
            : AdvertiserAudienceGeneratorType.RULES_BASED
      );
    const generationSteps = useMemo(
      () =>
        defaultAdvertiserAudienceGeneratorSteps.filter(
          (step) =>
            step !== AdvertiserAudienceGeneratorStep.SELECT_TYPE ||
            hasMultipleGenerators
        ),
      [hasMultipleGenerators]
    );
    const { availableAudiences } = useMediaInsightsDcrData();
    const selectGeneratorType = useCallback(
      (type: AdvertiserAudienceGeneratorType) => {
        setGeneratorType(type);
        setCurrentStep(AdvertiserAudienceGeneratorStep.CONFIGURATION);
      },
      [setGeneratorType, setCurrentStep]
    );
    const navigate = useNavigate();
    const cancelGeneration = useCallback(() => {
      navigate(`/datarooms/mi/${dataRoomId}/activation`);
    }, [navigate, dataRoomId]);
    const handleNextStep = useCallback(() => {
      if (currentStep === AdvertiserAudienceGeneratorStep.CONFIGURATION) {
        setCurrentStep(AdvertiserAudienceGeneratorStep.SUMMARY);
        return;
      }
    }, [currentStep, setCurrentStep]);
    const handleBackStep = useCallback(() => {
      if (currentStep === AdvertiserAudienceGeneratorStep.SELECT_TYPE) {
        cancelGeneration();
        return;
      }
      if (currentStep === AdvertiserAudienceGeneratorStep.CONFIGURATION) {
        setCurrentStep(AdvertiserAudienceGeneratorStep.SELECT_TYPE);
        setGeneratorType(null);
        return;
      }

      if (currentStep === AdvertiserAudienceGeneratorStep.SUMMARY) {
        setCurrentStep(AdvertiserAudienceGeneratorStep.CONFIGURATION);
      }
    }, [currentStep, cancelGeneration, setGeneratorType]);
    const isFirstStep = useCallback(
      (step: AdvertiserAudienceGeneratorStep) =>
        generationSteps.indexOf(step) === 0,
      [generationSteps]
    );
    const contextValue = useMemo<AdvertiserAudienceGeneratorContextValue>(
      () => ({
        availableAudiences,
        cancelGeneration,
        currentStep,
        generationSteps,
        generatorType,
        handleBackStep,
        handleNextStep,
        hasMultipleGenerators,
        isFirstStep,
        selectGeneratorType,
      }),
      [
        availableAudiences,
        generatorType,
        selectGeneratorType,
        generationSteps,
        currentStep,
        handleNextStep,
        handleBackStep,
        cancelGeneration,
        hasMultipleGenerators,
        isFirstStep,
      ]
    );
    return (
      <AdvertiserAudienceGeneratorProvider value={contextValue}>
        {children}
      </AdvertiserAudienceGeneratorProvider>
    );
  }
);

AdvertiserAudienceGeneratorWrapper.displayName =
  "AdvertiserAudienceGeneratorWrapper";
