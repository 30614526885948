import {
  useEnclaveWorkersQuery,
  useOrganizationEnclaveWorkersQuery,
  useOrganizationLicenseQuery,
  useUpdateOrganizationEnclaveWorkersMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  OrganizationState,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import {
  Box,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  List,
  ListItem,
} from "@mui/joy";
import { Fragment, memo, useCallback, useMemo } from "react";
import { WORKER_TYPE_LABEL } from "features/computeNode/components/ComputeNodeType/useComputeNodeType";
import { CommonSnackbarOrigin, useGeneralSnackbar, useUserRole } from "hooks";
import { WORKER_TYPE_COLOR } from "models";

interface OrganizationEnclaveWorkersEditorProps {
  organizationId: string;
}

const OrganizationEnclaveWorkersEditor: React.FC<
  OrganizationEnclaveWorkersEditorProps
> = ({ organizationId }) => {
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.ADMIN,
  });
  const { isSuperAdmin } = useUserRole();

  // List of all existing enclave workers
  const { data: enclaveWorkersData } = useEnclaveWorkersQuery({
    skip: !isSuperAdmin,
  });
  const enclaveWorkers = enclaveWorkersData?.workerTypes?.nodes || [];

  // List of enclave workers available to this organization
  const { data: organizationEnclaveWorkersData } =
    useOrganizationEnclaveWorkersQuery({
      variables: { organizationId },
    });
  const availableEnclaveWorkers = useMemo(
    () =>
      organizationEnclaveWorkersData?.organization?.workerTypes?.nodes || [],
    [organizationEnclaveWorkersData]
  );

  const { data: organizationLicenseData } = useOrganizationLicenseQuery({
    variables: { organizationId },
  });
  const organizationLicense = organizationLicenseData?.organization?.state;

  const [updateOrganizationEnclaveWorkersMutation] =
    useUpdateOrganizationEnclaveWorkersMutation();

  const updateOrganizationEnclaveWorkers = useCallback(
    async ({
      target: { value: workerId = "" },
    }: React.ChangeEvent<HTMLInputElement>) => {
      let newWorkersArray = [];
      if (availableEnclaveWorkers.some(({ id }) => id === workerId)) {
        // If selected worker is in availableEnclaveWorkers array - then it should be removed from a list
        newWorkersArray = availableEnclaveWorkers.reduce<string[]>(
          (workersArray, { id }) =>
            id === workerId ? workersArray : [id, ...workersArray],
          []
        );
      } else {
        // Adding new worker to the array otherwise
        const availableEnclaveWorkersIds = availableEnclaveWorkers.map(
          ({ id }) => id
        );
        newWorkersArray = [...availableEnclaveWorkersIds, workerId];
      }
      try {
        await updateOrganizationEnclaveWorkersMutation({
          variables: {
            input: {
              id: organizationId,
              workerTypes: newWorkersArray,
            },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          "Available computations settings could not be updated.",
          {
            context: error?.message,
            persist: true,
            variant: "error",
          }
        );
      }
    },
    [
      enqueueSnackbar,
      availableEnclaveWorkers,
      organizationId,
      updateOrganizationEnclaveWorkersMutation,
    ]
  );

  const isEditorDisabled = [
    OrganizationState.Passive,
    OrganizationState.Suspended,
  ].includes(organizationLicense);

  return (
    <FormControl>
      <FormLabel>Available computation types</FormLabel>
      {isSuperAdmin ? (
        <List orientation="horizontal">
          {enclaveWorkers.map(({ id, shortName }) => (
            <ListItem key={id}>
              <Checkbox
                checked={availableEnclaveWorkers.some(
                  (worker) => worker.id === id
                )}
                disabled={isEditorDisabled}
                label={
                  <span>
                    {WORKER_TYPE_LABEL[shortName]}{" "}
                    {shortName === WorkerTypeShortName.Python && (
                      <Checkbox
                        checked={availableEnclaveWorkers.some(
                          (worker) => worker.id === id
                        )}
                        disabled={true}
                        label="Matching"
                        sx={{ m: 0 }}
                      />
                    )}
                  </span>
                }
                onChange={updateOrganizationEnclaveWorkers}
                value={id}
              />
            </ListItem>
          ))}
        </List>
      ) : availableEnclaveWorkers.length ? (
        <Box sx={{ mt: 0.5 }}>
          {availableEnclaveWorkers.map(({ shortName }) =>
            shortName === WorkerTypeShortName.Python ? (
              <Fragment>
                <Chip
                  sx={{
                    backgroundColor:
                      WORKER_TYPE_COLOR[
                        shortName as keyof typeof WORKER_TYPE_COLOR
                      ],
                    mr: 1,
                  }}
                >
                  {shortName}
                </Chip>
                <Chip
                  sx={{
                    backgroundColor:
                      WORKER_TYPE_COLOR[
                        WorkerTypeShortName.Match as keyof typeof WORKER_TYPE_COLOR
                      ],
                    mr: 1,
                  }}
                >
                  Matching
                </Chip>
              </Fragment>
            ) : (
              <Chip
                sx={{
                  backgroundColor:
                    WORKER_TYPE_COLOR[
                      shortName as keyof typeof WORKER_TYPE_COLOR
                    ],
                  mr: 1,
                }}
              >
                {shortName}
              </Chip>
            )
          )}
        </Box>
      ) : (
        "—"
      )}
    </FormControl>
  );
};

OrganizationEnclaveWorkersEditor.displayName =
  "OrganizationEnclaveWorkersEditor";

export default memo(OrganizationEnclaveWorkersEditor);
