import { DqCard, DqCardContent } from "@decentriq/components";
import { faSparkles } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { useAdvertiserAudienceGenerator } from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator";
import {
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
} from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator/components";
import { useRulesBasedAdvertiserAudienceGenerator } from "../../contexts/RulesBasedAdvertiserAudienceGeneratorContext";

const RulesBasedAdvertiserAudienceGeneratorSummaryStep = memo(() => {
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  const { handleBackStep } = useAdvertiserAudienceGenerator();
  const {
    audienceName,
    estimatedAudienceSize,
    setAudienceName,
    generateLookalikeAudience,
    generateLookalikeAudienceLoading,
  } = useRulesBasedAdvertiserAudienceGenerator();
  return (
    <>
      <Grid item={true} mb={2} xs={7}>
        <AudienceGeneratorStepSectionTitle>
          1. Name your audience
        </AudienceGeneratorStepSectionTitle>
        <AudienceGeneratorStepSectionDescription>
          Provide a name that reflects your audience's purpose or focus.
        </AudienceGeneratorStepSectionDescription>
      </Grid>
      <Grid container={true} item={true} mb={2} xs={4}>
        <FormControl fullWidth={true}>
          <InputLabel>Audience name</InputLabel>
          <OutlinedInput
            label="Audience name"
            onChange={(event) => setAudienceName(event.target.value)}
            value={audienceName}
          />
        </FormControl>
      </Grid>
      <Grid item={true} mb={2} xs={7}>
        <AudienceGeneratorStepSectionTitle>
          2. Estimated audience size
        </AudienceGeneratorStepSectionTitle>
        <AudienceGeneratorStepSectionDescription>
          This section displays the estimated size of your new audience.
        </AudienceGeneratorStepSectionDescription>
      </Grid>
      <Grid container={true} item={true} mb={1} xs={4}>
        <Grid item={true} xs={12}>
          <DqCard>
            <DqCardContent sx={{ justifyContent: "center" }}>
              <Typography fontWeight="fontWeightSemiBold" variant="subtitle2">
                Estimated new audience size
              </Typography>
              <Typography fontSize={36} fontWeight={600}>
                {formatter.format(estimatedAudienceSize || 0)}
              </Typography>
            </DqCardContent>
          </DqCard>
        </Grid>
      </Grid>
      <Grid item={true} mb={2} mt={2} xs={7}>
        <AudienceGeneratorStepSectionTitle>
          3. Audience summary
        </AudienceGeneratorStepSectionTitle>
        <AudienceGeneratorStepSectionDescription>
          Details of your audience configuration below:
        </AudienceGeneratorStepSectionDescription>
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button
          color="inherit"
          onClick={handleBackStep}
          sx={{ mr: 1 }}
          variant="text"
        >
          Back
        </Button>
        <LoadingButton
          color="primary"
          loading={generateLookalikeAudienceLoading}
          loadingPosition="start"
          onClick={generateLookalikeAudience}
          startIcon={<FontAwesomeIcon icon={faSparkles} />}
          sx={{ height: "fit-content" }}
          variant="contained"
        >
          Generate results
        </LoadingButton>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

RulesBasedAdvertiserAudienceGeneratorSummaryStep.displayName =
  "RulesBasedAdvertiserAudienceGeneratorSummaryStep";

export default RulesBasedAdvertiserAudienceGeneratorSummaryStep;
