import { testIds } from "@decentriq/utils";
import {
  Checkbox,
  FormControl,
  FormLabel,
  Grid,
  Stack,
  Tooltip,
} from "@mui/joy";
import { memo } from "react";
import { RawSupportedFeatures } from "features/mediaDataRoom";
import { useCreationWizardConfiguration } from "features/mediaDataRoom/contexts";
import { CollaborationTypes } from "models";

const mediaDataRoomFeaturesTooltipMap: Record<
  CollaborationTypes,
  string | null
> = {
  [CollaborationTypes.Insights]:
    "If enabled, advertiser, publisher, agency and observer users will have access to the insights dashboards.",
  [CollaborationTypes.Remarketing]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created remarketing audiences and make them available to the publisher.",
  [CollaborationTypes.Lookalike]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can configure and generate lookalike audiences and make them available to the publisher.",
  [CollaborationTypes.Measurements]:
    "If enabled, advertiser users will be able to request measurement DCRs. This is a separate DCR that does rules-based attribution on brand conversions.",
  [CollaborationTypes.ExclusionTargeting]:
    "If enabled, advertiser and agency users will have access to the activation tab where they can see the automatically created exclusion targeting audiences and make them available to the publisher.",
  [CollaborationTypes.RuleBasedAudiences]:
    "If enabled, advertiser and agency users will be able to create rule-based audiences.",
};

const enabledFeaturesTooltipMap: Record<RawSupportedFeatures, string | null> = {
  [RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD]:
    "If enabled, advertiser and agency users will be able to export audiences they created.",
  [RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS]:
    "If enabled, absolute audience sizes will be shown in the overlap statistics in the data tab and (if Insights enabled) in the insights statistics in the insights tab.",
  [RawSupportedFeatures.ENABLE_MODEL_PERFORMANCE_EVALUATION]: null,
  [RawSupportedFeatures.ENABLE_EXTENDED_LAL_QUALITY_STATS]: null,
};

const CollaborationTypesEditor: React.FC = () => {
  const {
    allowedCollaborationTypes: allowedFeatures,
    enabledFeatures,
    setEnabledFeatures,
    showAbsoluteAudienceSizes,
    setShowAbsoluteAudienceSizes,
    showAdvertiserAudienceDownload,
    setEnableAdvertiserAudienceDownload,
    enableAdvertiserAudienceDownload,
  } = useCreationWizardConfiguration();
  return (
    <>
      <FormLabel>4. Collaboration types</FormLabel>
      <Grid container={true} mt={0} rowSpacing={2}>
        <Grid xs={6}>
          <Stack>
            {allowedFeatures.map((feature) => {
              const checked = enabledFeatures.includes(feature);
              const disabled =
                feature === CollaborationTypes.Remarketing &&
                enabledFeatures.includes(CollaborationTypes.RuleBasedAudiences);
              const tooltip =
                feature === CollaborationTypes.Remarketing && disabled
                  ? "Remarketing is automatically enabled due to Rule-based collaboration settings and cannot be changed manually"
                  : mediaDataRoomFeaturesTooltipMap[feature];
              return (
                <FormControl key={feature}>
                  <Tooltip placement="top" title={tooltip}>
                    <Checkbox
                      checked={checked}
                      data-testid={`${testIds.mediaInsightsDcr.collaborationConfigurationStepRole.allowedFeaturesHelper}${feature}`}
                      disabled={disabled}
                      label={feature}
                      onChange={() => setEnabledFeatures(feature)}
                      sx={{ width: "fit-content" }}
                    />
                  </Tooltip>
                </FormControl>
              );
            })}
          </Stack>
        </Grid>
        <Grid xs={6}>
          <Stack>
            <FormControl>
              <Tooltip
                placement="top"
                title={
                  enabledFeaturesTooltipMap[
                    RawSupportedFeatures.HIDE_ABSOLUTE_VALUES_FROM_INSIGHTS
                  ]
                }
              >
                <Checkbox
                  checked={showAbsoluteAudienceSizes}
                  label="Show absolute audience sizes"
                  onChange={(event) =>
                    setShowAbsoluteAudienceSizes(event.target.checked)
                  }
                  sx={{ width: "fit-content" }}
                />
              </Tooltip>
            </FormControl>
            {showAdvertiserAudienceDownload && (
              <FormControl>
                <Tooltip
                  placement="top"
                  title={
                    enabledFeaturesTooltipMap[
                      RawSupportedFeatures.ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD
                    ]
                  }
                >
                  <Checkbox
                    checked={enableAdvertiserAudienceDownload}
                    label="Allow advertiser and agency to export audiences"
                    onChange={(event) =>
                      setEnableAdvertiserAudienceDownload(event.target.checked)
                    }
                    sx={{ width: "fit-content" }}
                  />
                </Tooltip>
              </FormControl>
            )}
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

CollaborationTypesEditor.displayName = "CollaborationTypesEditor";

export default memo(CollaborationTypesEditor);
