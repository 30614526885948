// TODO: Fix joy migration
import {
  faBullhorn,
  faClock,
  faTable,
  faWarning,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Grid, Typography } from "@mui/joy";
import { List, ListItem, ListItemIcon } from "@mui/material";
import { format } from "date-fns";
import { memo } from "react";
import { DetailsGridValue } from "components";
import { useDataLabContext } from "features/dataLabs/contexts";
import {
  type DataLabDataset,
  matchingIdTypeAndHashingAlgorithmPresentation,
} from "features/dataLabs/models";

interface DataLabDetailsProps {
  id: string;
}

const DataLabDetails: React.FC<DataLabDetailsProps> = () => {
  const {
    dataLab: { data, loading },
  } = useDataLabContext();
  if (!data || loading) return <></>;
  const dataLab = data!;
  const {
    updatedAt,
    createdAt,
    matchingIdFormat,
    requireDemographicsDataset,
    requireEmbeddingsDataset,
    matchingIdHashingAlgorithm,
    numEmbeddings,
    segmentsDataset,
    demographicsDataset,
    embeddingsDataset,
    usersDataset,
  } = dataLab;
  const datasets: DataLabDataset[] = [
    segmentsDataset,
    usersDataset,
    demographicsDataset,
    embeddingsDataset,
  ].filter(Boolean) as DataLabDataset[];
  const dateFormat = "dd.MM.yyy, HH:mm";
  return (
    <Box>
      <Grid columnSpacing={2} container={true} rowSpacing={2}>
        {!!createdAt && (
          <Grid xs={updatedAt ? 6 : 12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>Created at</strong>
            </Typography>
            <DetailsGridValue
              icon={faClock}
              value={format(new Date(createdAt), dateFormat)}
            />
          </Grid>
        )}
        {!!updatedAt && (
          <Grid sm={6} xs={12}>
            <Typography component="div" gutterBottom={true} level="body-sm">
              <strong>Modified at</strong>
            </Typography>
            <DetailsGridValue
              icon={faClock}
              value={format(new Date(updatedAt), dateFormat)}
            />
          </Grid>
        )}
        <Grid sm={6} xs={12}>
          <Typography component="div" gutterBottom={true} level="body-sm">
            <strong>Data Configuration</strong>
          </Typography>
          <List disablePadding={true}>
            <ListItem>
              <Typography level="body-sm">
                <strong>Matching ID:</strong>{" "}
                {matchingIdTypeAndHashingAlgorithmPresentation({
                  matchingIdFormat,
                  matchingIdHashingAlgorithm,
                })}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography level="body-sm">
                <strong>Demographics data:</strong>{" "}
                {requireDemographicsDataset ? "Yes" : "No"}
              </Typography>
            </ListItem>
            <ListItem>
              <Typography level="body-sm">
                <strong>Embeddings data:</strong>{" "}
                {requireEmbeddingsDataset ? "Yes" : "No"}
              </Typography>
            </ListItem>
            {requireEmbeddingsDataset && (
              <ListItem>
                <Typography level="body-sm">
                  <strong>Number of embeddings:</strong> {numEmbeddings}
                </Typography>
              </ListItem>
            )}
          </List>
        </Grid>
        <Grid sm={6} xs={12}>
          <Typography component="div" gutterBottom={true} level="body-sm">
            <strong>Type</strong>
          </Typography>
          <DetailsGridValue
            icon={faBullhorn}
            value="Media DCR, AI-based Lookalike"
          />
        </Grid>
        <Grid xs={12}>
          <Typography component="div" gutterBottom={true} level="body-sm">
            <strong>Datasets</strong>
          </Typography>
          {datasets.length ? (
            <List disablePadding={true}>
              {datasets.map(({ id, name }) => (
                <ListItem key={id} sx={{ gap: 1 }}>
                  <ListItemIcon sx={{ color: "inherit", minWidth: "auto" }}>
                    <FontAwesomeIcon fixedWidth={true} icon={faTable} />
                  </ListItemIcon>
                  <Typography level="body-sm">{name}</Typography>
                </ListItem>
              ))}
            </List>
          ) : (
            <Box sx={{ alignItems: "center", display: "flex", gap: 1 }}>
              <FontAwesomeIcon fixedWidth={true} icon={faWarning} />
              <Typography color="grey.600" fontStyle="italic" level="body-sm">
                No datasets provisioned
              </Typography>
            </Box>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

DataLabDetails.displayName = "DataLabDetails";

export default memo(DataLabDetails);
