import { Typography } from "@mui/material";
import { memo } from "react";

const AudienceGeneratorStepSectionDescription = memo<React.PropsWithChildren>(
  ({ children }) => <Typography variant="body2">{children}</Typography>
);

AudienceGeneratorStepSectionDescription.displayName =
  "AudienceGeneratorStepSectionDescription";

export default AudienceGeneratorStepSectionDescription;
