import { Box, lighten, styled } from "@mui/material";

const AudienceGeneratorStepActionsWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: lighten(theme.palette.primary.main, 0.92),
  bottom: 0,
  display: "flex",
  justifyContent: "flex-end",
  left: "100px",
  padding: theme.spacing(1, 2),
  position: "fixed",
  right: 0,
}));

export default AudienceGeneratorStepActionsWrapper;
