import { memo, type ReactNode, useMemo, useState } from "react";
import { useMediaInsightsDcrData } from "features/MediaInsightsDcr/contexts";
import {
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "../../AdvertiserAudienceGenerator/contexts/AdvertiserAudienceGeneratorContext";
import {
  RulesBasedAdvertiserAudienceGeneratorConfigurationStep,
  RulesBasedAdvertiserAudienceGeneratorSummaryStep,
} from "../components";
import {
  type RulesBasedAdvertiserAudienceGeneratorContextValue,
  RulesBasedAdvertiserAudienceGeneratorProvider,
} from "../contexts/RulesBasedAdvertiserAudienceGeneratorContext";

const RulesBasedAdvertiserAudienceGenerator = memo(() => {
  const { currentStep } = useAdvertiserAudienceGenerator();
  const { availableAudiences } = useMediaInsightsDcrData();
  const [audienceName, setAudienceName] = useState<string>("");
  const [estimatedAudienceSize] = useState<number | null>(0);
  const contextValue =
    useMemo<RulesBasedAdvertiserAudienceGeneratorContextValue>(
      () => ({
        audienceName,
        availableAudiences,
        config: {},
        estimatedAudienceSize,
        generateLookalikeAudience: () => {},
        generateLookalikeAudienceLoading: false,
        setAudienceName,
      }),
      [availableAudiences, audienceName, setAudienceName, estimatedAudienceSize]
    );
  let step: ReactNode = null;
  switch (currentStep) {
    case AdvertiserAudienceGeneratorStep.CONFIGURATION:
      step = <RulesBasedAdvertiserAudienceGeneratorConfigurationStep />;
      break;
    case AdvertiserAudienceGeneratorStep.SUMMARY:
      step = <RulesBasedAdvertiserAudienceGeneratorSummaryStep />;
      break;
  }
  return (
    <RulesBasedAdvertiserAudienceGeneratorProvider value={contextValue}>
      {step}
    </RulesBasedAdvertiserAudienceGeneratorProvider>
  );
});

RulesBasedAdvertiserAudienceGenerator.displayName =
  "RulesBasedAdvertiserAudienceGenerator";

export default RulesBasedAdvertiserAudienceGenerator;
