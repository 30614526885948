import {
  DataSourceType,
  PermutiveServiceProvider,
  SalesforceProductType,
} from "@decentriq/graphql/dist/types";
import { ImportDatasetInputSchema } from "@decentriq/graphql/dist/validation";
import { type z } from "zod";

export const schema = ImportDatasetInputSchema();

export type SchemaType = z.infer<typeof schema>;

export const defaultValues = {
  datasetName: "",
};

type DeepPartial<T> = T extends object
  ? { [P in keyof T]?: DeepPartial<T[P]> }
  : T;

export const getDefaultValues = (
  type: DataSourceType
): DeepPartial<SchemaType> => {
  switch (type) {
    case DataSourceType.Salesforce:
      return {
        ...defaultValues,
        salesforce: {
          productType: SalesforceProductType.Core,
        },
      };
    case DataSourceType.Permutive:
      return {
        ...defaultValues,
        permutive: {
          serviceProvider: PermutiveServiceProvider.GoogleCloudStorage,
        },
      };
    default:
      return defaultValues;
  }
};
