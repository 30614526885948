import { FormControl, FormHelperText, FormLabel, Input, Stack } from "@mui/joy";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";

interface AzureFormControlsProps {
  type: ExternalConnectionType;
}

const AzureFormControls: React.FC<AzureFormControlsProps> = memo(({ type }) => {
  const { control } = useFormContext();
  return (
    <Stack>
      <ExternalConnectionConfigurationLabel />
      <Controller
        control={control}
        name="configuration.storageAccount"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>Storage account</FormLabel>
              <Input placeholder="Example: myazureaccount" {...field} />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="configuration.storageContainer"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>Storage container</FormLabel>
              <Input placeholder="Example: my-container" {...field} />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="configuration.blobName"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>Blob name</FormLabel>
              <Input placeholder="Example: my-file.csv" {...field} />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      {type === ExternalConnectionType.IMPORT ? (
        <Controller
          control={control}
          name="datasetName"
          render={({ field, fieldState }) => {
            const { error } = fieldState;
            return (
              <FormControl
                disabled={field.disabled}
                error={Boolean(error)}
                required={false}
              >
                <FormLabel>Stored dataset name</FormLabel>
                <Input placeholder="Example: DQ_dataset_name" {...field} />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
      ) : null}
      <ExternalConnectionCredentialsLabel />
      <Controller
        control={control}
        name="credentials.sasToken"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel>SAS Token</FormLabel>
              <Input
                placeholder="Example: sp=racw&st=2023-08-02T10:27:33Z..."
                {...field}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
    </Stack>
  );
});
AzureFormControls.displayName = "AzureFormControls";

export default AzureFormControls;
