import { Stack } from "@mui/joy";
import { memo } from "react";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import ControlledInput from "../ControlledInput/ControlledInput";

interface AzureFormControlsProps {
  type: ExternalConnectionType;
}

const AzureFormControls: React.FC<AzureFormControlsProps> = memo(({ type }) => {
  return (
    <Stack>
      <ExternalConnectionConfigurationLabel />
      <ControlledInput
        label="Storage account"
        name="azure.credentials.storageAccount"
        placeholder={`e.g. "myazureaccount"`}
      />
      <ControlledInput
        label="Storage container"
        name="azure.credentials.storageContainer"
        placeholder={`e.g. "my-container"`}
      />
      <ControlledInput
        label="Blob name"
        name="azure.credentials.blobName"
        placeholder={`e.g. "my-file.csv"`}
      />
      {type === ExternalConnectionType.IMPORT ? (
        <ControlledInput
          label="Stored dataset name"
          name="datasetName"
          placeholder={`e.g. "dataset-name"`}
          required={false}
        />
      ) : null}
      <ExternalConnectionCredentialsLabel />
      <ControlledInput
        label="SAS Token"
        name="azure.credentials.sasToken"
        placeholder={`e.g. "sp=racw&st=2023-08-02T10:27:33Z…"`}
      />
    </Stack>
  );
});
AzureFormControls.displayName = "AzureFormControls";

export default AzureFormControls;
