import { faKey, faLockKeyhole } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Typography } from "@mui/joy";
import { memo } from "react";

const ExternalConnectionCredentialsLabel: React.FC = () => {
  return (
    <Box>
      <Typography
        alignItems="center"
        display="flex"
        gap={0.75}
        gutterBottom={true}
        level="title-md"
      >
        <FontAwesomeIcon icon={faKey} />
        <span>Credentials</span>
      </Typography>
      <Alert
        color="secondary"
        size="sm"
        slotProps={{
          root: { sx: { alignItems: "flex-start" } },
          startDecorator: { sx: { display: "block" } },
        }}
        startDecorator={<FontAwesomeIcon icon={faLockKeyhole} />}
        variant="soft"
      >
        Credentials are encrypted and are used by the enclave just once.
      </Alert>
    </Box>
  );
};

ExternalConnectionCredentialsLabel.displayName =
  "ExternalConnectionCredentialsLabel";

export default memo(ExternalConnectionCredentialsLabel);
