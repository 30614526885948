import { Box, Grid } from "@mui/joy";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type InsightsComparisonViewFormValues,
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  MediaDataRoomInsightsAggregationSelector,
  MediaDataRoomInsightsComparisonItemTable,
  useMediaDataRoomOverlapInsightsContext,
} from "features/mediaDataRoom";
import { AffinityRatioSegmentBarChart } from "features/mediaDataRoom/components/MediaDataRoomInsights/components/MediaDataRoomInsightsBasicView/components";

const MediaDataRoomInsightsComparisonItem: React.FC = () => {
  const { audienceType } = useMediaDataRoomOverlapInsightsContext();
  const form = useForm<InsightsComparisonViewFormValues>({
    defaultValues: {
      aggregation: "",
      audienceType: audienceType || "",
    },
    mode: "onChange",
  });
  return (
    <FormProvider {...form}>
      <Grid container={true} spacing={4}>
        <Grid md={6} xs={12}>
          <MediaDataRoomInsightsAdvertiserAudienceSelector />
        </Grid>
        <Grid md={6} xs={12}>
          <MediaDataRoomInsightsAggregationSelector />
        </Grid>
      </Grid>
      <Box
        display="flex"
        flexDirection="column"
        sx={({ breakpoints }) => ({
          minHeight: 200,
          [breakpoints.up("md")]: { minHeight: 280 },
          [breakpoints.up("xl")]: { minHeight: 350 },
        })}
      >
        <AffinityRatioSegmentBarChart />
      </Box>
      <MediaDataRoomInsightsComparisonItemTable />
    </FormProvider>
  );
};
MediaDataRoomInsightsComparisonItem.displayName =
  "MediaDataRoomInsightsComparisonItem";

export default memo(MediaDataRoomInsightsComparisonItem);
