import {
  type DataSourceType,
  PermutiveServiceProvider,
} from "@decentriq/graphql/dist/types";
import { faFileLines, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  ListItemDecorator,
  Option,
  Select,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { Fragment, memo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import {
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
  ExternalConnectionsIcon,
} from "features/datasets/components/ExternalConnections";
import { dataSourceTypePresentation } from "features/datasets/models";
import ControlledInput from "../ControlledInput/ControlledInput";

const PermutiveFormControls: React.FC = memo(() => {
  const { control, watch, setError } = useFormContext();
  const permutiveServiceProvider = watch("permutive.serviceProvider");
  return (
    <Stack>
      <ExternalConnectionConfigurationLabel />
      <Controller
        control={control}
        name="permutive.serviceProvider"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl error={Boolean(error)}>
              <FormLabel>Cloud service Provider</FormLabel>
              <Select
                renderValue={(option) => {
                  if (!option) {
                    return null;
                  }
                  return (
                    <Fragment>
                      <ListItemDecorator>
                        <ExternalConnectionsIcon
                          connectionType={
                            option.value as unknown as DataSourceType
                          }
                        />
                      </ListItemDecorator>
                      {dataSourceTypePresentation.get(
                        option.value as unknown as DataSourceType
                      )}
                    </Fragment>
                  );
                }}
                slotProps={{
                  listbox: { sx: { "--ListItemDecorator-size": "32px" } },
                }}
                sx={{ "--ListItemDecorator-size": "32px" }}
                {...field}
                onChange={(event, value) => field.onChange(value)}
              >
                {Object.values(PermutiveServiceProvider).map((value) => (
                  <Option
                    key={value}
                    label={dataSourceTypePresentation.get(
                      value as unknown as DataSourceType
                    )}
                    value={value}
                  >
                    <ListItemDecorator>
                      <ExternalConnectionsIcon
                        connectionType={value as unknown as DataSourceType}
                      />
                    </ListItemDecorator>
                    {dataSourceTypePresentation.get(
                      value as unknown as DataSourceType
                    )}
                  </Option>
                ))}
              </Select>
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      {permutiveServiceProvider === PermutiveServiceProvider.S3 ? (
        <Fragment>
          <ControlledInput
            label="Bucket name"
            name="permutive.configuration.aws.bucket"
            placeholder={`e.g. "my-bucket-name"`}
          />
          <ControlledInput
            label="Region code"
            name="permutive.configuration.aws.region"
            placeholder={`e.g. "eu-central-2"`}
          />
        </Fragment>
      ) : null}
      {permutiveServiceProvider ===
      PermutiveServiceProvider.GoogleCloudStorage ? (
        <ControlledInput
          label="Bucket name"
          name="permutive.configuration.gcs.bucketName"
          placeholder={`e.g. "bucket-name"`}
        />
      ) : null}
      <Typography
        alignItems="center"
        display="flex"
        gap={0.75}
        level="title-md"
      >
        <FontAwesomeIcon icon={faFileLines} />
        <span>Publisher datasets</span>
      </Typography>
      <ControlledInput
        label="Matching dataset"
        name="permutive.datasets.matchingDatasetName"
        placeholder={`e.g. "matching.csv"`}
      />
      <ControlledInput
        label="Segments dataset"
        name="permutive.datasets.segmentsDatasetName"
        placeholder={`e.g. "segments.csv"`}
      />
      <ControlledInput
        label="Demographics dataset"
        name="permutive.datasets.demographicsDatasetName"
        placeholder={`e.g. "demographics.csv"`}
        required={false}
      />
      <ExternalConnectionCredentialsLabel />
      {permutiveServiceProvider === PermutiveServiceProvider.S3 ? (
        <Fragment>
          <ControlledInput
            label="Access key"
            name="permutive.credentials.aws.accessKey"
            placeholder={`e.g. "5JG8C6HNDPQF7W2X3Y1Z"`}
          />
          <ControlledInput
            label="Secret key"
            name="permutive.credentials.aws.secretKey"
            placeholder={`e.g. "F57yT3QaX2nD8jHr4KlP9cVs1W6zU0E2sY8hN4bM"`}
          />
        </Fragment>
      ) : null}
      {permutiveServiceProvider ===
      PermutiveServiceProvider.GoogleCloudStorage ? (
        <Controller
          control={control}
          name="permutive.credentials.gcs.credentials"
          render={({ field: { value, onChange, ...field }, fieldState }) => {
            const { error } = fieldState;
            return (
              <FormControl
                disabled={field.disabled}
                error={Boolean(error)}
                required={true}
              >
                <FormLabel>
                  Service account key in JSON format
                  <Box ml={0.5}>
                    <Tooltip
                      placement="top"
                      sx={{ maxWidth: 300 }}
                      title={
                        <span>
                          Please{" "}
                          <a
                            href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                            rel="noreferrer"
                            style={{ color: "inherit" }}
                            target="_blank"
                          >
                            follow the instructions
                          </a>{" "}
                          to obtain a service account key
                        </span>
                      }
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </Tooltip>
                  </Box>
                </FormLabel>
                <Input
                  {...field}
                  slotProps={{
                    input: {
                      accept: "application/JSON",
                      multiple: false,
                      onChange: (
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        const file = (event.target.files || [])[0];
                        const reader = new FileReader();
                        reader.onloadend = () => {
                          try {
                            if (!reader.result) {
                              throw new Error("Credentials file is not valid");
                            }
                            const parsedJSON = JSON.parse(
                              reader.result as string
                            );
                            const stringifiedJSON = JSON.stringify(
                              parsedJSON,
                              null,
                              4
                            );
                            if (!stringifiedJSON) {
                              throw new Error("Credentials file is not valid");
                            }
                            onChange(stringifiedJSON);
                          } catch (error) {
                            setError("gcs.credentials", {
                              message: error?.message,
                            });
                          }
                        };
                        reader.readAsText(file);
                      },
                      type: "file",
                    },
                    root: { sx: { alignItems: "center" } },
                  }}
                />
                <FormHelperText>{error?.message}</FormHelperText>
              </FormControl>
            );
          }}
        />
      ) : null}
    </Stack>
  );
});
PermutiveFormControls.displayName = "PermutiveFormControls";

export default PermutiveFormControls;
