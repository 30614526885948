import { type DataSourceType } from "@decentriq/graphql/dist/types";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  ModalDialog,
  Typography,
} from "@mui/joy";
import { memo, useEffect, useState } from "react";
import { type DataType } from "models";
import { ExternalDataSourceSelector } from "../ImportExternalDataDialog/components";
import LocalDataSourceSelector from "../ImportLocalDataDialog/LocalDataSourceSelector/LocalDataSourceSelector";

interface ImportTypeSelectionDialogProps {
  open: boolean;
  onCancel: () => void;
  onConfirmFromLocal: (type: DataType) => void;
  onConfirmFromExternal: (type: DataSourceType) => void;
}

const ImportTypeSelectionDialog = memo<ImportTypeSelectionDialogProps>(
  ({ open, onCancel, onConfirmFromExternal, onConfirmFromLocal }) => {
    const [externalDataSourceType, setExternalDataSourceType] =
      useState<DataSourceType | null>(null);
    const [localDataSourceType, setLocalDataSourceType] =
      useState<DataType | null>(null);
    const isLocalSelected = Boolean(localDataSourceType);
    const isExternalSelected = Boolean(externalDataSourceType);
    useEffect(() => {
      if (!open) {
        setExternalDataSourceType(null);
        setLocalDataSourceType(null);
      }
    }, [open, setLocalDataSourceType, setExternalDataSourceType]);
    useEffect(() => {
      if (isLocalSelected) {
        setExternalDataSourceType(null);
      }
    }, [isLocalSelected, setExternalDataSourceType]);
    useEffect(() => {
      if (isExternalSelected) {
        setLocalDataSourceType(null);
        return;
      }
    }, [isExternalSelected, setLocalDataSourceType]);
    const hasSelectedType = isLocalSelected || isExternalSelected;
    return (
      // TODO: Fix joy migration. Reuse DqSidePanel
      <Drawer
        anchor="right"
        open={open}
        slotProps={{
          content: {
            sx: {
              bgcolor: "transparent",
              boxShadow: "none",
              p: { md: 3, sm: 0 },
            },
          },
        }}
      >
        <ModalDialog
          sx={{
            height: "100%",
            maxHeight: "100%",
            maxWidth: "100%",
            minWidth: "100%",
            position: "relative",
            width: "100%",
          }}
        >
          <DialogTitle>Import dataset</DialogTitle>
          <Divider />
          <DialogContent>
            <Typography level="title-sm" sx={{ paddingInline: 2, pt: 1 }}>
              From your computer
            </Typography>
            <LocalDataSourceSelector
              onTypeChange={setLocalDataSourceType}
              selectedType={localDataSourceType}
            />
            <Typography level="title-sm" sx={{ paddingInline: 2 }}>
              From external source
            </Typography>
            <ExternalDataSourceSelector
              onTypeChange={setExternalDataSourceType}
              selectedType={externalDataSourceType}
            />
          </DialogContent>
          <Divider />
          <DialogActions>
            <Button onClick={onCancel}>Cancel</Button>
            <Button
              color="primary"
              disabled={!hasSelectedType}
              onClick={() => {
                if (!hasSelectedType) {
                  return;
                }
                if (isLocalSelected) {
                  onConfirmFromLocal(localDataSourceType as DataType);
                } else {
                  onConfirmFromExternal(
                    externalDataSourceType as DataSourceType
                  );
                }
              }}
              variant="solid"
            >
              Continue
            </Button>
          </DialogActions>
        </ModalDialog>
      </Drawer>
    );
  }
);

ImportTypeSelectionDialog.displayName = "ImportTypeSelectionDialog";

export default ImportTypeSelectionDialog;
