import { Box, Grid } from "@mui/joy";
import { memo } from "react";
import {
  OrganizationArchiveButton,
  OrganizationDataConnectorConfigurationsEditor,
  OrganizationDetails,
  OrganizationDomainEditor,
  OrganizationEnclaveWorkersEditor,
  OrganizationFeaturesEditor,
  OrganizationLicenseEditor,
  OrganizationLogoEditor,
  OrganizationMediaScopeEditor,
  OrganizationNameEditor,
} from "containers";
import { useUserRole } from "hooks";

const OrganizationForm: React.FC<{ organizationId: string }> = ({
  organizationId,
}) => {
  const { isSuperAdmin, isSuperAdminReadOnly } = useUserRole();
  return (
    <Box
      sx={{
        alignItems: "flex-start",
        background: "white",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid container={true} justifyContent="space-between" mb={2} xs={12}>
        <OrganizationLogoEditor organizationId={organizationId} />
        {isSuperAdmin && (
          <OrganizationArchiveButton organizationId={organizationId} />
        )}
      </Grid>
      <Grid container={true} xs={12}>
        <Grid xs={isSuperAdmin ? 7 : 12}>
          <Grid md={isSuperAdmin ? 8 : 5} xs={isSuperAdmin ? 11 : 8}>
            <OrganizationNameEditor organizationId={organizationId} />
          </Grid>
          <Grid md={3} mt={2} xs={6}>
            <OrganizationLicenseEditor organizationId={organizationId} />
          </Grid>
          <Grid mt={2}>
            <OrganizationEnclaveWorkersEditor organizationId={organizationId} />
          </Grid>
          <Grid mt={2}>
            <OrganizationFeaturesEditor organizationId={organizationId} />
          </Grid>
          <Grid md={7} mt={2} xs={9}>
            <OrganizationDomainEditor organizationId={organizationId} />
          </Grid>
          {(isSuperAdmin || isSuperAdminReadOnly) && (
            <>
              <Grid md={8} mt={2} xs={11}>
                <OrganizationMediaScopeEditor organizationId={organizationId} />
              </Grid>
              <Grid md={8} mt={2} xs={11}>
                <OrganizationDataConnectorConfigurationsEditor
                  organizationId={organizationId}
                />
              </Grid>
            </>
          )}
        </Grid>
        {(isSuperAdmin || isSuperAdminReadOnly) && (
          <Grid xs={5}>
            <OrganizationDetails organizationId={organizationId} />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

OrganizationForm.displayName = "OrganizationForm";

export default memo(OrganizationForm);
