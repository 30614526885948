import { useOrganizationMicrosoftDspMemberQuery } from "@decentriq/graphql/dist/hooks";
import { DataTargetType } from "@decentriq/graphql/dist/types";
import { faCircleInfo } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  List,
  ListItem,
  ListItemDecorator,
  Radio,
  RadioGroup,
} from "@mui/joy";
import { Fragment, memo, useCallback } from "react";
import { type MICROSOFT_DSP_MEMBER } from "containers/OrganizationDataConnectorConfigurationsEditor/OrganizationDataConnectorConfigurationsEditor";
import {
  dataTargetTypePresentation,
  ExternalConnectionsIcon,
  ExternalConnectionsIconSize,
} from "features/datasets";
import { EXPORT_DATASET_STEPS } from "features/datasets/components/ExportDataset";
import { useOrganizationPreferences } from "hooks";
import { useExportDatasetDialog } from "../../contexts";

type ExportDatasetTargetSelectorProps = {
  onClose: () => void;
};

const ExportDatasetTargetSelector: React.FC<
  ExportDatasetTargetSelectorProps
> = ({ onClose }) => {
  const { selectedDataTarget, setSelectedDataTarget, setExportStep } =
    useExportDatasetDialog();

  const handleDataSourceChange = useCallback(
    ({ target }: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedDataTarget(target.value as DataTargetType);
    },
    [setSelectedDataTarget]
  );

  const isTargetFormStepDisabled = [
    DataTargetType.TradeDesk,
    DataTargetType.Sportradar,
  ].includes(selectedDataTarget);

  const handleNextStepClick = useCallback(() => {
    // Remove when other data source type are implemented
    if (isTargetFormStepDisabled) {
      return;
    }
    setExportStep(EXPORT_DATASET_STEPS.TARGET_FORM);
  }, [isTargetFormStepDisabled, setExportStep]);

  const { organizationId } = useOrganizationPreferences();
  const { data: organizationMicrosoftDspMemberQueryData } =
    useOrganizationMicrosoftDspMemberQuery({ variables: { organizationId } });
  const microsoftDspMember = organizationMicrosoftDspMemberQueryData
    ?.organization?.microsoftDspMember as MICROSOFT_DSP_MEMBER;
  const isMicrosoftDspDisabled =
    !microsoftDspMember || microsoftDspMember === "None";

  const dataTargetTypeDisabledState: Partial<Record<DataTargetType, boolean>> =
    {
      [DataTargetType.MicrosoftDsp]: isMicrosoftDspDisabled,
      [DataTargetType.TradeDesk]: true,
      [DataTargetType.Sportradar]: true,
    };

  return (
    <Fragment>
      <DialogContent sx={{ flex: "1 1 auto" }}>
        <RadioGroup
          onChange={handleDataSourceChange}
          value={selectedDataTarget}
        >
          <List
            sx={{
              "--List-gap": "0.5rem",
              "--List-padding": "1rem",
              "--ListDivider-gap": "1rem",
              "--ListItem-paddingY": "1rem",
              "--ListItem-radius": "8px",
              "--ListItemDecorator-size": "32px",
            }}
          >
            {[
              DataTargetType.S3,
              DataTargetType.Azure,
              DataTargetType.GoogleCloudStorage,
              DataTargetType.GoogleAdManager,
              DataTargetType.Meta,
              DataTargetType.GoogleDv_360,
              DataTargetType.Permutive,
              DataTargetType.Adform,
              DataTargetType.MicrosoftDsp,
              DataTargetType.Splicky,
              DataTargetType.TradeDesk,
              DataTargetType.Sportradar,
            ].map((item) => (
              <ListItem key={item} sx={{ boxShadow: "sm" }} variant="outlined">
                <ListItemDecorator>
                  <ExternalConnectionsIcon
                    connectionType={item}
                    size={ExternalConnectionsIconSize.sm} // NOTE: Redundant, `--ListItemDecorator-size` can handle this
                  />
                </ListItemDecorator>
                <Radio
                  color="primary"
                  disabled={dataTargetTypeDisabledState[item] || false}
                  label={dataTargetTypePresentation.get(item)}
                  overlay={true}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: (theme) => ({
                        ...(checked && {
                          border: "2px solid",
                          borderColor: theme.vars.palette.primary[500],
                          inset: -1,
                        }),
                      }),
                    }),
                  }}
                  sx={{ flexDirection: "row-reverse", flexGrow: 1 }}
                  value={item}
                />
              </ListItem>
            ))}
          </List>
        </RadioGroup>
      </DialogContent>
      <Divider />
      <DialogContent sx={{ flex: "0 0 auto" }}>
        {selectedDataTarget === DataTargetType.S3 && (
          <Alert
            slotProps={{
              root: { sx: { alignItems: "flex-start" } },
              startDecorator: { sx: { display: "block" } },
            }}
            startDecorator={<FontAwesomeIcon icon={faCircleInfo} />}
            variant="soft"
          >
            <Box>
              <Box>
                The selected dataset will be directly exported to an Amazon S3
                bucket and stored as an object.
              </Box>
              <Box>
                Note: to verify the successful export, please directly access
                your S3 bucket.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.Azure && (
          <Alert variant="plain">
            <Box>
              <Box>
                The selected dataset will be directly exported to an Azure Blob
                Storage Container and stored as an object.
              </Box>
              <Box>
                Note: to verify the successful export, please directly access
                your Container.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.GoogleCloudStorage && (
          <Alert variant="plain">
            <Box>
              <Box>
                The selected dataset will be directly exported to a Google Cloud
                Storage Bucket and stored as an object.
              </Box>
              <Box>
                Note: to verify the successful export, please directly access
                your Bucket.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.GoogleAdManager && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will export the selected dataset as an Audience Segment in
                Google Ad Manager 360. The dataset should have only one column
                with the list of identifiers. The type of identifier and the
                segment can be specified in the next screen.
              </Box>
              <Box>
                Note: Please access your Google Ad Manager 360 account to verify
                the created audience segment.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.Meta && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will export the selected dataset directly as an audience to
                Meta Ads Manager. The dataset should have only one column with
                plain email addressed that will be automatically hashed with
                SHA256.
              </Box>
              <Box>
                Note: Please access your Meta Ads Manager to verify the created
                audience.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.GoogleDv_360 && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will export the selected dataset directly as First- and
                Third-party audience to GoogleDV360. The dataset should have
                only one column with plain email addressed that will be
                automatically hashed with SHA256.
              </Box>
              <Box>
                Note: Please access your GoogleDV360 account to verify the
                created audience.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.Permutive && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will export the selected dataset directly as an imported
                segment on Permutive using a Cloud Storage service. The dataset
                should have only one column with Permutive IDs, and the segment
                will be added automatically to them.
              </Box>
              <Box>
                Note: Please access your Permutive account to verify the
                imported segment.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.TradeDesk && (
          <Alert variant="plain">
            <Box>
              <Box>
                The Trade Desk is a demand-side platform (DSP) that provides a
                technology platform for advertisers to manage and optimize
                programmatic advertising campaigns across various digital
                channels and inventory sources.
              </Box>
              <Box>Exporting to The Trade Desk is coming soon.</Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.MicrosoftDsp && (
          <Alert variant="plain">
            <Box>
              <Box>
                Export your audiences directly to the Microsoft Invest DSP. The
                dataset should have only one column, that contains OneLog
                OneIDs. Currently, only OneIDs are supported.
              </Box>
              <Box>
                Note: Please access your Microsoft Invest account to verify the
                created audience.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.Splicky && (
          <Alert variant="plain">
            <Box>
              <Box>
                This will export the selected dataset directly as an audience in
                Splicky. The dataset should have only one column, that contains
                OneLog OneIDs. Currently, only OneIDs are supported.
              </Box>
              <Box>
                Note: Please access your Splicky account to verify the created
                audience.
              </Box>
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.Adform && (
          <Alert variant="plain">
            <Box>
              Export your audiences directly to your Adform DSP seat. Please
              make sure to use this only with Adform-supported ID types. For
              details, please check the documentation.
            </Box>
          </Alert>
        )}
        {selectedDataTarget === DataTargetType.Sportradar && (
          <Alert variant="plain">
            <Box>
              <Box>
                Sportradar ad:s is a demand-side platform (DSP) dedicated to the
                sport and fan ecosystem. It accepts a variety of IDs to be
                targeted on different channels dedicated to sport, generic
                purpose publishers and social media. The integration supports
                the FanID service.
              </Box>
              <Box>
                Please contact us to enable this integration for your
                organization.
              </Box>
            </Box>
          </Alert>
        )}
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          color="primary"
          disabled={isTargetFormStepDisabled}
          onClick={handleNextStepClick}
          variant="solid"
        >
          Continue
        </Button>
      </DialogActions>
    </Fragment>
  );
};

ExportDatasetTargetSelector.displayName = "ExportDatasetTargetSelector";

export default memo(ExportDatasetTargetSelector);
