// TODO: Fix joy migration
import { InfoTooltip } from "@decentriq/components";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionCredentialsLabel,
} from "features/datasets/components/ExternalConnections";

type GoogleDV360FormProps = ExportDatasetFormProps;

const MAX_MEMBERSHIP_DURATION = 10000;

const GoogleDV360FormValidationSchema = yup.object().shape({
  configuration: yup.object({
    advertiserId: yup.string().trim().required("Advertiser ID is required"),
    description: yup.string().trim(),
    displayName: yup.string().trim().required("Audience name is required"),
    membershipDuration: yup.string(),
  }),
  credentialsFile: yup.mixed().required("Credentials file is required"),
});

type GoogleDV360FormValues = yup.InferType<
  typeof GoogleDV360FormValidationSchema
>;

const GoogleDV360Form: React.FC<GoogleDV360FormProps> = ({
  onCancel: cancelFormHandler,
  onSubmit: submitFormHandler,
}) => {
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      configuration: {
        advertiserId: "",
        description: "",
        displayName: "",
        membershipDuration: MAX_MEMBERSHIP_DURATION,
      },
      credentialsFile: undefined,
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(GoogleDV360FormValidationSchema),
  });

  const handlePreviousStepClick = useCallback(() => {
    cancelFormHandler();
    reset();
  }, [reset, cancelFormHandler]);

  const handleFormSubmit = useCallback(
    (formValues: GoogleDV360FormValues) => {
      const {
        configuration: {
          advertiserId = "",
          description = "",
          displayName = "",
          membershipDuration = MAX_MEMBERSHIP_DURATION,
        } = {},
        credentialsFile,
      } = formValues;

      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          const credentialsJSON = JSON.parse(reader?.result);
          const credentials = JSON.stringify(credentialsJSON, null, 4);
          if (!credentials) {
            throw new Error("Credentials file is not valid");
          }
          submitFormHandler({
            input: {
              dv360: {
                advertiserId: advertiserId.trim(),
                credentials,
                description: description.trim(),
                displayName: displayName.trim(),
                membershipDurationDays: membershipDuration.toString(),
              },
            },
          });
        };
        reader.readAsText(credentialsFile);
      } catch (error) {
        throw new Error(error as string);
      }
      reset();
    },
    [reset, submitFormHandler]
  );

  return (
    <Box>
      <form>
        <Stack>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.advertiserId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.advertiserId;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>Advertiser ID</FormLabel>
                  <Input placeholder="Example: 1234512345" {...field} />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.displayName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.displayName;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>Audience name</FormLabel>
                  <Input placeholder="Example: Customer match" {...field} />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.description"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.description;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>Description (optional)</FormLabel>
                  <Input placeholder="Example: DCR Audience" {...field} />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.membershipDuration"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.membershipDuration;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>
                    Membership duration in days{" "}
                    <InfoTooltip
                      tooltip={
                        <>
                          The duration in days that an entry remains in the
                          audience after the qualifying event. If the audience
                          has no expiration, set the value of this field to
                          10000. Otherwise, the set value must be greater than 0
                          and less than or equal to 540.
                        </>
                      }
                    />
                  </FormLabel>
                  <Input
                    slotProps={{
                      input: {
                        max: MAX_MEMBERSHIP_DURATION,
                        min: 0,
                        type: "number",
                      },
                    }}
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <ExternalConnectionCredentialsLabel />
          <Controller
            control={control}
            name="credentialsFile"
            render={({
              field: { value, onChange, ...restField },
              formState,
            }) => {
              const { errors } = formState;
              const fieldError = errors?.credentialsFile;
              return (
                <FormControl error={!isEmpty(fieldError)}>
                  <FormLabel>
                    Service account key in JSON format
                    <InfoTooltip
                      tooltip={
                        <>
                          Please{" "}
                          <a
                            href="https://cloud.google.com/iam/docs/keys-create-delete#creating"
                            rel="noreferrer"
                            style={{ color: "inherit" }}
                            target="_blank"
                          >
                            follow the instructions
                          </a>{" "}
                          to obtain a service account key
                        </>
                      }
                    />
                  </FormLabel>
                  <Input
                    {...restField}
                    slotProps={{
                      input: {
                        accept: "application/JSON",
                        multiple: false,
                        onChange: (
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => onChange((event?.target?.files || [])[0]),
                        type: "file",
                      },
                      root: { sx: { alignItems: "center" } },
                    }}
                    value={value?.fileName}
                  />
                  <FormHelperText>{fieldError?.message}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Stack>
      </form>
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          justifyContent: "space-between",
          padding: "16px 0",
          width: "100%",
        }}
      >
        <Button onClick={handlePreviousStepClick}>Back</Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="solid"
        >
          Export
        </Button>
      </Box>
    </Box>
  );
};

GoogleDV360Form.displayName = "GoogleDV360Form";

export default memo(GoogleDV360Form);
