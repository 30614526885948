import { type IconProp } from "@fortawesome/fontawesome-svg-core";
import { faFile, faTable } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List, ListItem, ListItemDecorator, Radio, RadioGroup } from "@mui/joy";
import { memo } from "react";
import { type DataType } from "models";

interface LocalDataSourceSelectorProps {
  onTypeChange: (type: DataType) => void;
  selectedType: DataType | null;
}

interface LocalDataSourceTypeOption {
  title: string;
  type: DataType;
  icon: IconProp;
}

const localDataSourceTypeOptions: LocalDataSourceTypeOption[] = [
  {
    icon: faFile,
    title: "Upload as a file",
    type: "unstructured",
  },
  {
    icon: faTable,
    title: "Upload as a table",
    type: "table",
  },
];

const LocalDataSourceSelector = memo<LocalDataSourceSelectorProps>(
  ({ selectedType, onTypeChange }) => (
    <RadioGroup
      onChange={(event) => onTypeChange(event.target.value as DataType)}
      value={selectedType}
    >
      <List
        sx={{
          "--List-gap": "0.5rem",
          "--List-padding": "1rem",
          "--ListDivider-gap": "1rem",
          "--ListItem-paddingY": "1rem",
          "--ListItem-radius": "8px",
          "--ListItemDecorator-size": "32px",
        }}
      >
        {localDataSourceTypeOptions.map(({ type, title, icon }) => (
          <ListItem key={type} sx={{ boxShadow: "sm" }} variant="outlined">
            <ListItemDecorator>
              <FontAwesomeIcon fixedWidth={true} icon={icon} />
            </ListItemDecorator>
            <Radio
              color="primary"
              label={title}
              overlay={true}
              slotProps={{
                action: ({ checked }) => ({
                  sx: (theme) => ({
                    ...(checked && {
                      border: "2px solid",
                      borderColor: theme.vars.palette.primary[500],
                      inset: -1,
                    }),
                  }),
                }),
              }}
              sx={{ flexDirection: "row-reverse", flexGrow: 1 }}
              value={type}
            />
          </ListItem>
        ))}
      </List>
    </RadioGroup>
  )
);
LocalDataSourceSelector.displayName = "LocalDataSourceSelector";

export default LocalDataSourceSelector;
