// TODO: Fix joy migration
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Stack,
} from "@mui/joy";
import isEmpty from "lodash/isEmpty";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
  ExternalConnectionType,
} from "features/datasets/components/ExternalConnections";
import { type ImportExternalDataFormProps } from "../../../../types";
import {
  GoogleCloudStorageFormBucketField,
  GoogleCloudStorageFormCredentials,
} from "./components";

type GoogleCloudStorageFormProps = (
  | ImportExternalDataFormProps
  | ExportDatasetFormProps
) & {
  type: ExternalConnectionType;
  ActionsWrapper?: React.ComponentType;
  FormWrapper?: React.ComponentType;
};

const GoogleCloudStorageFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    bucketName: yup.string().trim().required("Bucket name is required"),
    objectName: yup.string().trim().required("Object name is required"),
  }),
  credentials: yup.object({
    credentialsFile: yup.mixed().required("Credentials file is required"),
  }),
  datasetName: yup.string(),
});

type GoogleCloudStorageFormValues = yup.InferType<
  typeof GoogleCloudStorageFormValidationSchema
>;

const GoogleCloudStorageForm: React.FC<GoogleCloudStorageFormProps> = ({
  onSubmit,
  onCancel,
  submitButtonText = "Import",
  defaultValues,
  type = ExternalConnectionType.IMPORT,
  ActionsWrapper = ExternalConnectionActionsWrapper,
  FormWrapper = Box,
}) => {
  const form = useForm({
    defaultValues: {
      configuration: {
        bucketName: "",
        objectName:
          type === ExternalConnectionType.IMPORT
            ? ""
            : defaultValues?.datasetName,
      },
      credentials: {
        credentialsFile: undefined,
      },
      ...(type === ExternalConnectionType.IMPORT
        ? { datasetName: defaultValues?.datasetName || "" }
        : {}),
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(GoogleCloudStorageFormValidationSchema),
  });
  const { control, handleSubmit, reset } = form;

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: GoogleCloudStorageFormValues) => {
      const {
        configuration: { bucketName = "", objectName = "" } = {},
        credentials: { credentialsFile } = {},
        datasetName = "",
      } = formValues;

      try {
        const reader = new FileReader();
        reader.onloadend = () => {
          const credentialsJSON = JSON.parse(reader?.result);
          const credentials = JSON.stringify(credentialsJSON, null, 4);
          if (!credentials) {
            throw new Error("Credentials file is not valid");
          }
          onSubmit({
            input: {
              ...(type === ExternalConnectionType.IMPORT
                ? { datasetName: datasetName?.trim() || objectName?.trim() }
                : {}),
              gcs: {
                bucketName: bucketName.trim(),
                credentials,
                objectName: objectName.trim(),
              },
            },
          });
        };
        reader.readAsText(credentialsFile);
      } catch (error) {
        throw new Error(error as string);
      }
      reset();
    },
    [reset, onSubmit, type]
  );

  return (
    <>
      <FormWrapper>
        <FormProvider {...form}>
          <Stack>
            <ExternalConnectionConfigurationLabel />
            <GoogleCloudStorageFormBucketField />
            <Controller
              control={control}
              name="configuration.objectName"
              render={({ field, formState }) => {
                const { errors } = formState;
                const fieldError = errors?.configuration?.objectName;
                return (
                  <FormControl error={!isEmpty(fieldError)}>
                    <FormLabel>Object name</FormLabel>
                    <Input placeholder="Example: my-object-name" {...field} />
                    <FormHelperText>{fieldError?.message}</FormHelperText>
                  </FormControl>
                );
              }}
            />
            {type === ExternalConnectionType.IMPORT && (
              <Controller
                control={control}
                name="datasetName"
                render={({ field, formState }) => {
                  const { errors } = formState;
                  const fieldError = errors?.datasetName;
                  return (
                    <FormControl error={!isEmpty(fieldError)}>
                      <FormLabel>Stored dataset name (optional)</FormLabel>
                      <Input
                        placeholder="Example: DQ_dataset_name"
                        {...field}
                      />
                      <FormHelperText>{fieldError?.message}</FormHelperText>
                    </FormControl>
                  );
                }}
              />
            )}
            <GoogleCloudStorageFormCredentials />
          </Stack>
        </FormProvider>
      </FormWrapper>
      <ActionsWrapper>
        <Button onClick={handlePreviousStepClick}>Back</Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="solid"
        >
          {submitButtonText}
        </Button>
      </ActionsWrapper>
    </>
  );
};

GoogleCloudStorageForm.displayName = "GoogleCloudStorageForm";

export default memo(GoogleCloudStorageForm);
