import { Grid } from "@mui/joy";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type InsightsDetailedViewFormValues,
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  useMediaDataRoomOverlapInsightsContext,
} from "features/mediaDataRoom";
import {
  AffinityRatioSegmentDemographicsBarChart,
  AffinityRatioSegmentDemographicsTable,
  GenderBarChart,
} from "./components";

const MediaDataRoomInsightsDetailedView: React.FC = () => {
  const { audienceType } = useMediaDataRoomOverlapInsightsContext();
  const form = useForm<InsightsDetailedViewFormValues>({
    defaultValues: {
      audienceType: audienceType || "",
      dataSortDirection: "desc",
      genderChartValueKey: "shareInOverlap",
    },
    mode: "onChange",
  });
  return (
    <FormProvider {...form}>
      <Grid container={true} overflow="auto" spacing={3}>
        <Grid container={true} md={4} xs={12}>
          <MediaDataRoomInsightsAdvertiserAudienceSelector />
        </Grid>
        <Grid mb={2} minHeight={250} xs={12}>
          <AffinityRatioSegmentDemographicsBarChart />
        </Grid>
        <Grid container={true} spacing={5} xs={12}>
          <Grid container={true} mb={2} md={6} minHeight={230} xs={12}>
            <GenderBarChart />
          </Grid>
          <Grid container={true} md={6} xs={12}>
            <AffinityRatioSegmentDemographicsTable />
          </Grid>
        </Grid>
      </Grid>
    </FormProvider>
  );
};
MediaDataRoomInsightsDetailedView.displayName =
  "MediaDataRoomInsightsDetailedView";

export default memo(MediaDataRoomInsightsDetailedView);
