// TODO: Fix joy migration
import { FormControl, FormHelperText, FormLabel, Input } from "@mui/joy";
import { isEmpty } from "lodash";
import { memo } from "react";
import { Controller, useFormContext } from "react-hook-form";

const GoogleCloudStorageFormBucketField = () => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name="configuration.bucketName"
      render={({ field, formState }) => {
        const { errors } = formState;
        const fieldError = errors?.configuration?.bucketName;
        return (
          <FormControl error={!isEmpty(fieldError)}>
            <FormLabel>Bucket name</FormLabel>
            <Input placeholder="Example: my-bucket-name" {...field} />
            <FormHelperText>{fieldError?.message}</FormHelperText>
          </FormControl>
        );
      }}
    />
  );
};

GoogleCloudStorageFormBucketField.displayName =
  "GoogleCloudStorageFormBucketField";

export default memo(GoogleCloudStorageFormBucketField);
