import { createContext, useContext } from "react";
import { OverlapInsightsExecutionContext } from "features/mediaDataRoom";

interface OverlapInsightsContextValue {
  executionContext: OverlapInsightsExecutionContext; // Defines whether insights rendered on the Insights tab, or Publisher's Insights Modal, ModalDialog, or Advertiser's Insights dialog
  reach?: number;
  audienceType?: string;
  isInsightsDialogOpen: boolean;
  openInsightsDialog: () => void;
  closeInsightsDialog: () => void;
}

export const MediaDataRoomOverlapInsightsContext =
  createContext<OverlapInsightsContextValue>({
    audienceType: "",
    closeInsightsDialog: () => {},
    executionContext: OverlapInsightsExecutionContext?.insightsTab,
    isInsightsDialogOpen: false,
    openInsightsDialog: () => {},
    reach: undefined,
  });

export const MediaDataRoomOverlapInsightsProvider =
  MediaDataRoomOverlapInsightsContext.Provider;

export const useMediaDataRoomOverlapInsightsContext = () =>
  useContext(MediaDataRoomOverlapInsightsContext);

export default useMediaDataRoomOverlapInsightsContext;
