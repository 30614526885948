import { DqCard, DqCardContent } from "@decentriq/components";
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  Typography,
} from "@mui/material";
import { memo } from "react";
import { getAudiencePresentation } from "features/legacyMediaDataRoom/types";
import { usePublishedMediaInsightsDcr } from "features/MediaInsightsDcr";
import {
  AdvertiserAudienceGeneratorStep,
  useAdvertiserAudienceGenerator,
} from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator";
import {
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
} from "features/MediaInsightsDcr/containers/AdvertiserAudienceGenerator/components";
import { useOrganizationPreferences } from "hooks";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";
import {
  AudienceGeneratorReachSlider,
  AudienceQualityStatistics,
  ExcludeSeedAudienceCheckbox,
} from "../";

const LookalikeAdvertiserAudienceGeneratorConfigurationStep = memo(() => {
  const {
    selectedAudienceType,
    setSelectedAudienceType,
    openExcludeSeedAudienceInfoDialog,
    selectedLookalikeAudience,
    activatedLookalikeAudiences = [],
    availableAudiences: { computeResults: { audienceTypes = [] } = {} },
  } = useLookalikeAdvertiserAudienceGenerator();
  const { handleBackStep, handleNextStep, isFirstStep, cancelGeneration } =
    useAdvertiserAudienceGenerator();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  const {
    supportedFeatures: { enableExtendedLalQualityStats = false },
  } = usePublishedMediaInsightsDcr();
  const { allowExcludingSeedAudience } = useOrganizationPreferences();
  const shouldCancelGenerationOnBack = isFirstStep(
    AdvertiserAudienceGeneratorStep.CONFIGURATION
  );
  return (
    <>
      <Grid container={true} item={true} mb={8} xs={12}>
        <Grid item={true} xs={7}>
          <AudienceGeneratorStepSectionTitle>
            1. AI Lookalike audience setup
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Choose the audience that will be used to seed your lookalike model.
          </AudienceGeneratorStepSectionDescription>
        </Grid>
        <Grid item={true} xs={5}>
          <FormControl fullWidth={true}>
            <FormLabel sx={{ fontSize: "12px" }}>Advertiser Audience</FormLabel>
            <Select
              displayEmpty={true}
              onChange={(event: SelectChangeEvent<string>) =>
                setSelectedAudienceType(event?.target.value)
              }
              renderValue={getAudiencePresentation}
              value={selectedAudienceType || ""}
            >
              {audienceTypes.map((audienceType) => {
                return (
                  <MenuItem key={audienceType} value={audienceType}>
                    <ListItemText
                      primary={getAudiencePresentation(audienceType)}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {enableExtendedLalQualityStats && (
            <Box display="flex" gap={2} mt={2}>
              <DqCard sx={{ flex: 1 }}>
                <DqCardContent>
                  <Typography variant="subtitle2">
                    Addressable audience
                  </Typography>
                  <Typography fontWeight={800}>
                    {formatter.format(
                      selectedLookalikeAudience?.addressable_audience_size || 0
                    )}
                  </Typography>
                </DqCardContent>
              </DqCard>
              <DqCard sx={{ flex: 1 }}>
                <DqCardContent>
                  <Typography variant="subtitle2">Seed audience</Typography>
                  <Typography fontWeight={800}>
                    {formatter.format(
                      selectedLookalikeAudience?.seed_audience_size || 0
                    )}
                  </Typography>
                </DqCardContent>
              </DqCard>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container={true} item={true} mb={2} xs={12}>
        <Grid item={true} xs={7}>
          <AudienceGeneratorStepSectionTitle>
            2. Define the Reach
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Set the precision and reach for your lookalike model
          </AudienceGeneratorStepSectionDescription>
        </Grid>
        <Grid item={true} xs={5}>
          <AudienceGeneratorReachSlider />
          {allowExcludingSeedAudience && <ExcludeSeedAudienceCheckbox />}
        </Grid>
      </Grid>
      <Grid container={true} item={true} xs={12}>
        <AudienceQualityStatistics />
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button
          color="inherit"
          onClick={
            shouldCancelGenerationOnBack ? cancelGeneration : handleBackStep
          }
          sx={{ mr: 1 }}
          variant="text"
        >
          {shouldCancelGenerationOnBack ? "Cancel" : "Back"}
        </Button>
        <Button
          color="primary"
          disabled={!selectedAudienceType}
          onClick={
            !allowExcludingSeedAudience ||
            activatedLookalikeAudiences.length > 0
              ? handleNextStep
              : openExcludeSeedAudienceInfoDialog
          }
          variant="contained"
        >
          Continue
        </Button>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

LookalikeAdvertiserAudienceGeneratorConfigurationStep.displayName =
  "LookalikeAdvertiserAudienceGeneratorConfigurationStep";

export default LookalikeAdvertiserAudienceGeneratorConfigurationStep;
