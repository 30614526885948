import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
} from "@mui/joy";
import { memo, useCallback } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";
import { type ExportDatasetFormProps } from "features/datasets/components/ExportDataset";
import {
  ExternalConnectionActionsWrapper,
  ExternalConnectionConfigurationLabel,
} from "features/datasets/components/ExternalConnections";

const SplickyFormValidationSchema = yup.object().shape({
  configuration: yup.object({
    seatId: yup.string().trim().required("Seat ID is required"),
    userDefinedAudienceName: yup
      .string()
      .trim()
      .required("Audience name is required"),
  }),
});

type SplickyFormValues = yup.Asserts<typeof SplickyFormValidationSchema>;

const SplickyForm: React.FC<ExportDatasetFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
}) => {
  const form = useForm({
    defaultValues: {
      configuration: {
        seatId: "",
        userDefinedAudienceName: "",
      },
    },
    mode: "onChange",
    reValidateMode: "onChange",
    resolver: yupResolver(SplickyFormValidationSchema),
  });
  const { handleSubmit, reset, control } = form;

  const handlePreviousStepClick = useCallback(() => {
    onCancel();
    reset();
  }, [reset, onCancel]);

  const handleFormSubmit = useCallback(
    (formValues: SplickyFormValues) => {
      const {
        configuration: { seatId, userDefinedAudienceName },
      } = formValues;

      onSubmit({
        input: {
          splicky: {
            seatId,
            userDefinedAudienceName,
          },
        },
      });
      reset();
    },
    [reset, onSubmit]
  );

  return (
    <>
      <FormProvider {...form}>
        <Box>
          <ExternalConnectionConfigurationLabel />
          <Controller
            control={control}
            name="configuration.seatId"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.seatId;
              return (
                <FormControl error={!!fieldError}>
                  <FormLabel>Seat ID</FormLabel>
                  <Input
                    error={!!fieldError}
                    placeholder="Example: 7165"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message || ""}</FormHelperText>
                </FormControl>
              );
            }}
          />
          <Controller
            control={control}
            name="configuration.userDefinedAudienceName"
            render={({ field, formState }) => {
              const { errors } = formState;
              const fieldError = errors?.configuration?.userDefinedAudienceName;
              return (
                <FormControl error={!!fieldError}>
                  <FormLabel>Audience Name</FormLabel>
                  <Input
                    error={!!fieldError}
                    placeholder="Example: DCR Audience"
                    {...field}
                  />
                  <FormHelperText>{fieldError?.message || ""}</FormHelperText>
                </FormControl>
              );
            }}
          />
        </Box>
      </FormProvider>
      <ExternalConnectionActionsWrapper>
        <Button onClick={handlePreviousStepClick}>Back</Button>
        <Button
          color="primary"
          onClick={handleSubmit(handleFormSubmit)}
          variant="solid"
        >
          Export
        </Button>
      </ExternalConnectionActionsWrapper>
    </>
  );
};

SplickyForm.displayName = "SplickyForm";

export default memo(SplickyForm);
