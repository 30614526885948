import { faDownload } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ListItemDecorator, MenuItem } from "@mui/joy";
import { memo, type SetStateAction, useCallback } from "react";
import { type ActivationType, type Audience } from "features/mediaDataRoom";
import useDownloadAudience from "./useDownloadAudience";

type ActivationDownloadMenuItemProps = {
  activationType: ActivationType;
  activatedAudiences: Audience[];
  audienceType: string;
  reach: number;
  setExportAudienceLoading: (isLoading: SetStateAction<boolean>) => void;
};

const ActivationDownloadMenuItem: React.FC<ActivationDownloadMenuItemProps> = ({
  activationType,
  activatedAudiences,
  audienceType,
  reach,
  setExportAudienceLoading,
}) => {
  const { downloadAudience } = useDownloadAudience({
    activatedAudiences,
    activationType,
    audienceType,
    reach,
  });
  const handleDownloadAudience = useCallback(async () => {
    setExportAudienceLoading(true);
    await downloadAudience();
    setExportAudienceLoading(false);
  }, [downloadAudience, setExportAudienceLoading]);
  return (
    <MenuItem onClick={handleDownloadAudience}>
      <ListItemDecorator>
        <FontAwesomeIcon fixedWidth={true} icon={faDownload} />
      </ListItemDecorator>
      Download file
    </MenuItem>
  );
};
ActivationDownloadMenuItem.displayName = "ActivationDownloadMenuItem";

export default memo(ActivationDownloadMenuItem);
